// 意大利语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'Messaggio',
    IMErrorMessage: 'Connessione IM non riuscita, fare clic per riprovare! ',
  },
  "appWebSocketDetails": {
    sendOption: 'Invia',
    send: 'invia',
    fileFail: 'Carica e formatta le immagini',
    IMportError: 'Attualmente non connesso, riprova! ',
    IMErrorMessage: 'Connessione IM non riuscita, riprova! ',
    retryBtn: 'Riprova',
  },
  "appLoginIndex": {
    navTitle: "Accedi",
    projectName: 'Gestisci negozio',
    formLabel1: 'E-mail',
    formLabel2: 'password',
    placeholder: 'Inserisci',
    hint: "Se non hai un account",
    clickRegister: 'Fai clic per registrarti',
    forgetPass: 'password dimenticata',
    optionBtn: 'Accedi',
    validateError: "Completa l'input! ",
  },
  "appForgetPassIndex": {
    navTitle: 'Password dimenticata',
    formLabel1: 'Casella di posta di accesso',
    formLabel2: 'Codice di verifica',
    formLabel3: 'Nuova password',
    formLabel4: 'Conferma password',
    formHint1: 'Inserisci la tua email di accesso',
    formHint2: 'Inserisci una nuova password',
    formHint3: 'Inserisci il codice di verifica',
    formHint4: 'Inserisci la tua password due volte',
    formOption: 'Invia',
    sendOption: 'Conto alla rovescia:',
    optionsBtn: 'Conferma',
  },
  'footer': {
    home: "negozio",
    project: 'prodotto',
    info: 'Messaggio',
    order: 'ordine',
    user: 'mio',
  },
  'setDrawer': {
    label1: 'Impostazioni multilingue',
    label2: 'Registrazione del negozio',
    label3: 'Accesso al negozio',
    label4: "Servizio clienti",
    label5: "Scarica l'applicazione Android",
    label6: "Scarica l'applicazione ios",
    optionsBtn: 'Annulla',
  },
  'appHomeIndex': {
    accountLabel1: 'Vendite',
    accountLabel2: 'traffico',
    cardLabel1: 'Panoramica dei dati',
    cardLabel2: 'Rapporto dati',
    cardLabel3: 'Panoramica importo',
    ech1Title: 'Vendite totali',
    ech2Title: 'Numero totale di ordini',
    ech3Title: 'Traffico nel negozio',
    echBuoy1Title: 'Vendite totali',
    echBuoy2Title: 'Numero totale di ordini',
    echBuoy3Title: 'Traffico nel negozio',
    dataCardTitle1: 'Vendite',
    dataCardTitle2: 'Traffico',
    dataCardTitle3: 'Numero totale di ordini',
    dataCardTitle4: 'Importo totale dovuto',
    dataCardDayTags: 'Oggi',
    dataYesterTags: "Dati di ieri",
    dataYesterTags1: "Quantità di ordini non controllati",
    cardMenusLabel1: 'Tasse',
    cardMenusLabel2: 'Valore memorizzato',
    cardMenusLabel3: 'Prelievo',
    cardMenusLabel4: 'Condividi invito',
    cardMenusLabel5: 'Elenco prodotti',
    cardMenusLabel6: 'Elenco ordini',
    cardMenusLabel7: 'Resoconto aziendale',
    taxPopupTitle: 'Attualmente devo pagare le tasse:',
    popupBtn: 'Vai e paga le tasse',
    cardStateLabel1: 'Saldo',
    cardStateLabel2: 'Prelievo',
    cardStateLabel3: 'Reddito',
    cardStateLabel4: 'Squadra',
    cardSellHotTitle: 'I 10 articoli più venduti',
    salesLabel: 'Vendite:',
  },
  'appSetLanguage': {
    navTitle: 'Impostazioni multilingue',
    affirm: 'confermare',
  },
  "appServiceIndex": {
    navTitle: "Servizio clienti",
    customerService: 'Servizio clienti esclusivo',
    helloTitle: 'Ciao',
    welcomeTitle: 'Sono tuo<, puoi contattarmi attraverso i seguenti canali',
    iconInformation: 'Seleziona il software di comunicazione per la consultazione',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: 'Saldo',
    blanceOption1: 'Prelievo',
    blanceOption2: 'Memorizza valore',
    incomeLabel1: 'Reddito cumulativo',
    incomeLabel2: 'Prelievi cumulativi',
    listLabel1: 'Informazioni sul negozio',
    listLabel2: 'Impostazioni del metodo di prelievo',
    listLabel3: 'Registro del fondo',
    listLabel4: 'Relazione aziendale',
    listLabel5: 'Attività di sconto',
    listLabel7: 'Prestito',
    listLabel8: 'Vai al centro commerciale',
    listLabel9: "Scarica l'applicazione",
    listLabelLast: 'Esci',
  },
  'downloadAppIndex': {
    navTitle: 'Scarica',
    downloadAndroidApp: "Download Android",
    downloadIosApp: "Download IOS",
  },
  "appDiscountActivity": {
    navTitle: "Attività di sconto",
    popupLabel1: 'importo',
    popupPlace1: "Inserisci l'importo",
    popupLabel2: 'Sconto',
    popupPlace2: 'Inserisci lo sconto',
    popupOption: 'Conferma',
    listLabel1: 'Importo richiesto:',
    listLabel2: 'Rapporto di sconto:',
    listLabel3: 'Tempo di sconto:',
    dialogTitle: "Conferma se eliminare!",
    dialogAffirm: 'Conferma',
    dialogClose: "Annulla",
  },
  "appInviteIndex": {
    navTitle: 'Invita nuove attività',
    headerTitle: "Invita gli amici per guadagnare premi",
    copyOption: 'copia',
    copySuccess: 'Copia riuscita',
    inviteTitle: "Numero totale di inviti",
    inviteLabel1: 'Numero di persone invitate:',
    inviteLabel2: 'Commissione totale:',
    ruleTitle: 'Regola di invito',
    ruleHint1: " 1. L'invitante può invitare gli amici a completare la registrazione condividendo il link della promozione e gli amici invitati verranno associati a te;",
    ruleHint2: ' 2. Dopo aver invitato gli utenti a completare il pagamento, puoi ottenere una quota dei loro profitti;',
    ruleHint3: ' 3. Sono supportati solo i premi di invito di terzo livello e il rapporto di ricompensa è: <%, >%, @% ',
  },
  "appInviteUserRecordIndex": {
    navTitle: 'Invita record utente',
    recordTitle1: 'Numero totale di utenti',
    recordTitle2: 'Livello 1',
    recordTitle3: 'Livello 2',
    recordTitle4: 'Livello 3',
    tabLabel1: 'tutti',
    tabLabel2: 'Livello 1',
    tabLabel3: 'Secondo livello',
    tabLabel4: 'Livello 3',
    listLabel1: 'Livello:',
    listLabel2: 'Profitto:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: "Record delle entrate degli inviti",
    recordTitle1: "Entrate totali",
    recordTitle2: 'Livello 1',
    recordTitle3: 'Livello 2',
    recordTitle4: 'Livello 3',
    tabLabel1: 'tutti',
    tabLabel2: 'Livello 1',
    tabLabel3: 'Secondo livello',
    tabLabel4: 'Livello 3',
    listLabel1: 'Profitto:',
    listLabel2: 'Proporzione:',
  },
  "appWithdrawIndex": {
    navTitle: 'Ritiro',
    currencyType1: 'valuta fiat',
    currencyType2: 'valuta virtuale',
    formLabel1: 'Tipo di valuta:',
    formPlace1: 'Seleziona il tipo di valuta',
    formLabel2: 'Seleziona valuta:',
    formPlace2: 'Seleziona il tipo di valuta',
    formLabel3: 'Importo del prelievo:',
    formPlace3: "Inserisci l'importo del prelievo",
    formLabel4: 'Password di prelievo:',
    formPlace4: 'Inserisci la password di prelievo:',
    balanceLabel: 'Saldo attuale:',
    amountLabel: 'Arrivo effettivo:',
    optionsBtn: 'Invia',
    dialogClose: 'Annulla',
    dialogAffirm: 'Conferma',
    currencySelectError: 'Seleziona il tipo di valuta',
    currencyError: 'Seleziona la valuta',
    affirmDialogDialogTitle1: 'Non hai impostato una password di pagamento. Vuoi configurarlo? ',
    affirmDialogDialogTitle2: 'Non hai impostato il canale di prelievo, vuoi impostarlo? ',
    affirmDialogBtn2: 'Annulla',
    affirmDialogBtn1: 'Conferma',
  },
  'appSetWithdrawalIndex': {
    navTitle: 'Impostazione del metodo di prelievo',
    currencyType1: 'valuta fiat',
    currencyType2: 'valuta virtuale',
    formLabel1: 'Tipo di valuta:',
    formPlace1: 'Seleziona il tipo di valuta',
    formLabel2: 'Seleziona valuta:',
    formPlace2: 'Seleziona il tipo di valuta',
    addressLabel: 'Indirizzo:',
    addressPlace: "Inserisci l'indirizzo della valuta virtuale",
    hintLabel: 'Suggerimento importante',
    hintValue: 'Trasferisci solo asset da ¥',
    optionsBtn: 'Invia',
    dialogClose: 'Annulla',
    dialogAffirm: 'Conferma',
    currencySelectError: 'Seleziona il tipo di valuta',
    currencyError: 'Seleziona la valuta',
    currencyFail: 'Non ancora aperto',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: 'nome reale',
    formPlace1: 'Per favore inserisci il tuo vero nome',
    formLabel2: 'E-mail',
    formPlace2: 'Inserisci la tua email',
    formLabel3: 'Numero di cellulare',
    formPlace3: 'Inserisci il tuo numero di cellulare',
    formLabel4: 'tipo PIX',
    formPlace4: 'Seleziona il tipo PIX',
    formLabel5: 'Account PIX',
    formPlace5: 'Inserisci il numero del tuo account PIX',
    formLabel6: 'Nome della banca',
    formPlace6: 'Inserisci il nome della banca',
    formLabel7: 'Conto bancario',
    formPlace7: 'Inserisci il conto bancario',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: 'Paese/Regione',
    formPlace1: 'Inserisci paese/regione',
    formLabel2: 'nome',
    formPlace2: 'Inserisci un nome',
    formLabel3: 'Cognome',
    formPlace3: 'Inserisci il tuo cognome',
    formLabel4: 'veloce',
    formPlace4: 'Inserisci swift',
    formLabel5: 'Nome della banca',
    formPlace5: 'Inserisci il nome della banca',
    formLabel6: 'iban',
    formPlace6: "Inserisci l'iban",
    formLabel7: 'Numero di cellulare',
    formPlace7: 'Inserisci il tuo numero di cellulare',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: 'Paese/Regione',
    formPlace1: 'Inserisci paese/regione',
    formLabel2: 'nome',
    formPlace2: 'Inserisci un nome',
    formLabel3: 'Cognome',
    formPlace3: 'Inserisci il tuo cognome',
    formLabel4: 'veloce',
    formPlace4: 'Inserisci swift',
    formLabel5: 'ach',
    formPlace5: 'Inserisci ach',
    formLabel6: 'Nome della banca',
    formPlace6: 'Inserisci il nome della banca',
    formLabel7: 'Conto bancario',
    formPlace7: 'Inserisci il conto bancario',
    formLabel8: 'Provincia o stato',
    formPlace8: 'Inserisci la provincia o lo stato',
    formLabel9: 'Numero di cellulare',
    formPlace9: 'Inserisci il tuo numero di cellulare',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: 'Paese/Regione',
    formPlace1: 'Inserisci paese/regione',
    formLabel2: 'nome',
    formPlace2: 'Inserisci un nome',
    formLabel3: 'Cognome',
    formPlace3: 'Inserisci il tuo cognome',
    formLabel4: 'Codice bancario',
    formPlace4: 'Inserisci il codice bancario',
    formLabel5: 'Nome della banca',
    formPlace5: 'Inserisci il nome della banca',
    formLabel6: 'Conto bancario',
    formPlace6: 'Inserisci il conto bancario',
    formLabel7: 'Numero di cellulare',
    formPlace7: 'Inserisci il tuo numero di cellulare',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: 'Paese/Regione',
    formPlace1: 'Inserisci paese/regione',
    formLabel2: 'nome',
    formPlace2: 'Inserisci un nome',
    formLabel3: 'Cognome',
    formPlace3: 'Inserisci il tuo cognome',
    formLabel4: 'zengin',
    formPlace4: 'Inserisci zengin',
    formLabel5: 'Nome della banca',
    formPlace5: 'Inserisci il nome della banca',
    formLabel6: 'Conto bancario',
    formPlace6: 'Inserisci il conto bancario',
    formLabel7: 'Provincia o stato',
    formPlace7: 'Inserisci la provincia o lo stato',
    formLabel8: 'Numero di cellulare',
    formPlace8: 'Inserisci il tuo numero di cellulare',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: 'Paese/Regione',
    formPlace1: 'Inserisci paese/regione',
    formLabel2: 'nome',
    formPlace2: 'Inserisci un nome',
    formLabel3: 'Cognome',
    formPlace3: 'Inserisci il tuo cognome',
    formLabel4: 'BSB',
    formPlace4: 'Inserisci BSB',
    formLabel5: 'Nome della banca',
    formPlace5: 'Inserisci il nome della banca',
    formLabel6: 'Conto bancario',
    formPlace6: 'Inserisci il conto bancario',
    formLabel7: 'Provincia o stato',
    formPlace7: 'Inserisci la provincia o lo stato',
    formLabel8: 'Numero di cellulare',
    formPlace8: 'Inserisci il tuo numero di cellulare',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'nome',
    formPlace1: 'Inserisci un nome',
    formLabel2: 'Cognome',
    formPlace2: 'Inserisci il tuo cognome',
    formLabel3: 'Nome banca',
    formPlace3: 'Inserisci il nome della banca',
    formLabel4: 'Conto bancario',
    formPlace4: 'Inserisci il conto bancario',
    formLabel5: 'Numero di cellulare',
    formPlace5: 'Inserisci il tuo numero di cellulare',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Nome della banca',
    formPlace1: 'Inserisci il nome della banca',
    formLabel2: 'Conto',
    formPlace2: 'Inserisci il tuo account',
    formLabel3: 'conto clabe',
    formPlace3: 'Inserisci il tuo account Clabe',
    formLabel4: 'Numero di cellulare',
    formPlace4: 'Inserisci il tuo numero di cellulare',
  },
  'appStatisticsReportIndex': {
    navTitle: "Relazione operativa",
    cardLabel1: 'Vendite totali',
    cardLabel2: "Importo totale dell'acquisto",
    cardLabel3: 'Profitto totale',
    cardLabel4: 'Numero totale di ordini',
    listLabel1: 'Ora:',
    listLabel2: 'Ordine:',
    listLabel3: 'Vendite:',
    listLabel4: 'Importo di acquisto:',
    profitLabel: 'profitto',
    searchDateTitle: 'Ora del documento',
    searchIntervalTitle: 'Intervallo di tempo',
    searchStartTimePlace: 'Ora di inizio',
    searchEndTimePlace: 'Ora di fine',
    searchOptions1: 'Ripristina',
    searchOptions2: 'Conferma',
    searchDateAffirm: 'conferma',
    searchDateClose: 'Annulla',
    searchIntervalLabel1: 'tutti',
    searchIntervalLabel2: 'Oggi',
    searchIntervalLabel3: 'ieri',
    searchIntervalLabel4: 'Questa settimana',
    searchIntervalLabel5: 'questo mese',
    searchIntervalLabel6: "quest'anno",
    searchIntervalOptions: 'Annulla',
  },
  "appAmountRechargeIndex": {
    navTitle: 'Valore memorizzato',
    currencyType1: 'valuta fiat',
    currencyType2: 'valuta virtuale',
    formLabel1: 'Tipo di valuta:',
    formPlace1: 'Seleziona il tipo di valuta',
    formLabel2: 'Seleziona valuta:',
    formPlace2: 'Seleziona il tipo di valuta',
    formLabel3: 'Importo del valore memorizzato:',
    formPlace3: "Inserisci l'importo del valore memorizzato",
    formLabel4: 'Metodo di ricarica',
    formPlace4: 'Seleziona il metodo di archiviazione:',
    balanceLabel: 'Saldo attuale:',
    amountLabel: 'Arrivo effettivo:',
    optionsBtn: 'Invia',
    dialogClose: 'Annulla',
    dialogAffirm: 'Conferma',
    currencySelectError: 'Seleziona il tipo di valuta',
    currencyError: 'Seleziona la valuta',
    successCopy: 'Copia riuscita',
    hintLabel: "Promemoria importante:",
    hintValue: 'È consentito solo il trasferimento di asset in ¥',
    legalHint: 'Si prega di contattare il servizio clienti!'
  },
  'appShopInfoIndex': {
    navTitle: "Informazioni sul negozio",
    navEditText: 'Modifica',
    formLabel1: 'Memorizza immagine',
    formLabel2: 'nome negozio',
    formLabel3: 'E-mail di accesso',
    formLabel4: 'codice invito',
    formLabel5: 'Password di accesso',
    formLabel6: 'Password di pagamento',
    formLabel7: 'Messaggio di descrizione',
    formLabel8: 'Salva immagine di sfondo',
    formLabel9: 'Immagine di sfondo del mercato',
    formLabel10: 'Informazioni sul certificato',
    formHint1: 'Fronte del documento',
    formHint2: 'Il retro del certificato',
    fileFail: 'Carica immagini e formato',
    optionsBtn: 'Invia',
  },
  'appShopInfoEdit': {
    navTitle: "Editor delle informazioni del negozio",
    formLabel1: 'Memorizza immagine',
    formLabel2: 'nome negozio',
    formLabel3: 'E-mail di accesso',
    formLabel4: 'codice invito',
    formLabel7: 'Messaggio di descrizione',
    formLabel8: 'Salva immagine di sfondo',
    formLabel9: 'Immagine di sfondo del mercato',
    fileFail: 'Carica immagini e formato',
    optionsBtn: 'Invia',
  },
  'appChangeLoginPass': {
    navTitle: 'Cambia password di accesso',
    formLabel1: 'Vecchia password',
    formLabel2: 'Nuova password',
    formLabel3: 'Codice di verifica',
    formHint1: 'Inserisci la vecchia password',
    formHint2: 'Inserisci una nuova password',
    formHint3: 'Inserisci il codice di verifica',
    formOption: 'Invia',
    sendOption: 'Conto alla rovescia:',
    optionsBtn: 'Conferma',
  },
  'appChangePayPass': {
    navTitle: 'Cambia password di pagamento',
    formLabel1: 'Password di pagamento',
    formLabel2: 'Codice di verifica',
    formHint1: 'Inserisci la password di pagamento',
    formHint2: 'Inserisci il codice di verifica',
    formOption: 'Invia',
    sendOption: 'Conto alla rovescia:',
    optionsBtn: 'Conferma',
  },
  "appFundingRecordsIndex": {
    navTitle: "Record del fondo",
    searchRecordsTitle: 'Tipo di fondo',
    searchDateTitle: "Selezione dell'ora",
    searchFundingLabel1: "Tutti i fondi",
    searchFundingLabel2: 'Tipo di deposito',
    searchFundingLabel3: 'Record di prelievo',
    searchOptions1: 'Ripristina',
    searchOptions2: 'Conferma',
    searchDateAffirm: 'conferma',
    searchDateClose: 'Annulla',
    stareTimePlace: 'Ora di inizio',
    endTimePlace: 'Ora di fine',
    typeAll: 'tutto',
  },
  "appProjectIndex": {
    navTitle: 'Elenco prodotti',
    searchPlace: 'Inserisci il nome del prodotto che desideri cercare',
    shelvesLabel: 'ripiani superiori e inferiori',
    brandPlace: "Seleziona un marchio",
    classPlace: 'Seleziona una categoria',
    searchOptions1: "Ripristina",
    searchOptions2: 'Conferma',
    shelvesTag1: "tutti",
    shelvesTag2: "sugli scaffali",
    shelvesTag3: "fuori dagli scaffali",
    salesLabel: 'Vendite:',
    dialogAffirm: 'Conferma',
    dialogClose: 'Annulla',
    listLabel: 'Su e giù per gli scaffali:',
  },
  "appProjectLibraryIndex": {
    navTitle: "Libreria prodotti",
    searchPlace: 'Inserisci il nome del prodotto che desideri cercare',
    brandPlace: "Seleziona un marchio",
    classPlace: 'Seleziona una categoria',
    searchOptions1: "Ripristina",
    searchOptions2: 'Conferma',
    salesLabel: 'Vendite:',
    dialogAffirm: 'Conferma',
    dialogClose: 'Annulla',
    productPackageLabel1: 'Product Package',
    productPackageLabel2: "(Aggiungi rapidamente il prodotto)",

    selectAll: 'Scelta multipla',
    selectHint: 'Supporta solo fino a 50 prodotti',
    submitOptions: 'Submit',
    dialogHint: 'Sei sicuro di elencare in batch questo prodotto?',
  },
  'appProductPackageIndex': {
    navTitle: "Pacchetto prodotto",
    listLabel1: "Classificazione stellare raccomandata:",
    listLabel2: 'Sincronization status:',
    listLabel3: "Quantità di prodotto:",
    optionsBtn: "Sync",
    dialogHint: 'Sei sicuro di voler utilizzare questo pacchetto di prodotti?',
    dialogOptions1: 'Annulla',
    dialogOptions2: "Conferma",
    messageToast: 'Invio riuscito, sincronizzazione delle informazioni sul prodotto in corso...',
  },
  'appProductPackageList': {
    navTitle: "Product Package Details",
    salesLabel: 'Vendite:',
  },
  "appProductFirstIndex": {
    navTitle: "Informazioni sul prodotto",
    nextStep: 'Passaggio successivo',
    label1: 'Nome del prodotto:',
    label2: 'Marchio del prodotto:',
    label3: 'Categoria prodotto:',
    label4: 'Prezzo minimo di acquisto:',
    label5: 'Prezzo di vendita minimo:',
    label6: 'Prezzo minimo di mercato:',
    label7: 'Immagine di copertina:',
    label8: 'Immagine del prodotto:',

  },
  'appProductNextStepIndex': {
    navTitle: 'Specifiche del prodotto',
    previous: 'passaggio precedente',
    navBtn: 'Invia',
    label1: 'Prezzo di acquisto:',
    placeHint: 'Inserisci',
    label2: 'Prezzo di vendita:',
    label3: 'Prezzo di mercato:',
    specLabel: 'Attributo di specifica:',
    optionBtn: "Conferma",
    validateMessage1: 'Il prezzo originale non può essere inferiore al prezzo di acquisto',
    validateMessage2: 'Il prezzo originale non può essere inferiore al prezzo di vendita corrente',
    validateMessage3: 'Il prezzo di vendita non può essere inferiore al prezzo di acquisto',
    validateMessage4: 'Il prezzo di vendita non può essere superiore al prezzo originale',
    shelvesTitle: 'Vuoi caricare o scaricare gli scaffali? ',
    shelvesOptions1: 'No',
    shelvesOptions2: 'Sì',
  },
  "appOrderIndex": {
    tagsLabel1: 'Tutti',
    orderTotalNumber: "Numero totale dell'ordine",
    listLabel1: "Data dell'ordine:",
    listLabel2: 'Vendite totali:',
    listLabel3: 'Quantità prodotto:',
    listLabel4: "Stato dell'ordine:",
    listLabel5: 'Stato del pagamento:',
    listHint: 'Acquista',
    searchOptionBtn: 'Cerca',
    searchPlace: 'Inserisci il contenuto della ricerca',
    searchDateStartPlace: 'Ora di inizio',
    searchDateEndPlace: 'Ora di fine',
    searchDateAffirm: 'conferma',
    searchDateClose: 'Annulla',
    searchTypePayTitle: 'Stato del pagamento',
    payTags1: 'Tutti',
    payTags2: 'Pagamento in sospeso',
    payTags3: 'Pagato',
    searchTypeOrderTitle: "Stato dell'ordine",
    searchOptions1: 'Ripristina',
    searchOptions2: 'Conferma',
    purDialogTitle: 'Acquista tutto',
    purDialogLabel1: 'Importo di acquisto:',
    purDialogLabel2: 'Importo delle vendite:',
    purDialogLabel3: 'Importo del profitto:',
    purOptions: "Conferma",
    purRowTitle: 'Acquisto',
    purRowLabel1: 'Importo di acquisto:',
    purRowLabel2: 'Importo delle vendite:',
    purRowLabel3: 'Importo del profitto:',
  },
  "appOrderDetailIndex": {
    navTitle: "Dettagli dell'ordine",
    detailInfoLabel1: 'Numero ordine:',
    detailInfoLabel2: "Ora dell'ordine:",
    detailInfoLabel3: 'Metodo di pagamento:',
    detailInfoLabel4: "Stato dell'ordine:",
    detailInfoLabel5: 'Stato del pagamento:',
    productLabel1: 'Informazioni sul prodotto',
    productAttributesLabel: 'Attributi del prodotto:',
    productAccountLabel1: 'Vendite:',
    productAccountLabel2: 'Importo di acquisto:',
    countInfoLabel1: 'Vendite totali:',
    countInfoLabel2: 'Importo totale di acquisto:',
    countInfoLabel3: 'Profitto totale:',
    countInfoLabel4: 'Quantità totale:',
    countInfoLabel5: 'Rapporto di sconto:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Logistica degli ordini',
    logisticsLabel: 'Ordine',
    listLabel1: "Nome:",
    listLabel2: "Telefono:",
    listLabel3: "Codice postale:",
    listLabel4: "Indirizzo:",
  },
  "appOrderDetailRecord": {
    navTitle: 'Record ordine',
    logisticsLabel: 'Ordine',
    recordEnumLabel1: "Il cliente crea l'ordine",
    recordEnumLabel2: 'Ordine di pagamento del cliente',
    recordEnumLabel3: 'Ordine di acquisto del negozio',
    recordEnumLabel4: "L'ordine inizia a essere spedito",
    recordEnumLabel5: "L'utente conferma la ricezione",
    recordEnumLabel6: 'Il rimborso del sistema arriva',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: 'Aggiornamento versione, confermare? ',
    loadingTitle: 'Scaricamento in corso...',
    systemUpgrade: 'Aggiornamento del sistema',
    upgradeImmediately: 'Conferma',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Caricamento in corso",
    finishedText: "Nessuno",
    loadingText: "Caricamento in corso",
    nullText: 'Ancora nessuna informazione',
    loadingFinished: "Caricamento completato",
    listNullText: 'Non più',
    pullIngText: 'Scorri verso il basso per aggiornare',
    loosingText: 'Aggiornamento della versione',
    pullingSuccess: 'Caricamento pull-down riuscito',
    webSocketSuccess: 'Hai un nuovo messaggio',
    imLinkSuccess: 'Connessione al servizio IM riuscita! ',
    imNonsupport: 'Questo browser non supporta websocket',
    imLinkLoading: 'IM si sta collegando, per favore attendi...',
    imLinkErrorLoading: 'Connessione IM non riuscita, aggiorna la pagina o contatta lo sviluppatore! ',
    dialogHint: 'Hai già un account?',
    dialogClose: 'Register',
    dialogAffirm: 'Login',
  },
};
export default obj;
