// 德语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: "Nachricht",
    IMErrorMessage: "IM- Verbindung fehlgeschlagen, klicken Sie hier, um es erneut zu versuchen!",

  },
  "appWebSocketDetails": {
    sendOption: "Senden",
    send: "senden",
    fileFail: "Bitte Bilder hochladen und formatieren",
    IMportError: "Derzeit keine Verbindung, bitte versuchen Sie es erneut!",
    IMErrorMessage: "IM- Verbindung fehlgeschlagen, bitte versuchen Sie es erneut!",
    retryBtn: "Wiederholen",
  },
  "appLoginIndex": {
    navTitle: "Anmelden",
    projectName: "Shop verwalten",
    formLabel1: "E- Mail",
    formLabel2: "Passwort",
    placeholder: "Bitte eingeben",
    hint: "Wenn Sie noch kein Konto haben,",
    clickRegister: "Klicken Sie hier, um sich zu registrieren",
    forgetPass: "Passwort vergessen",
    optionBtn: "Anmelden",
    validateError: "Bitte vervollständigen Sie die Eingabe!",
  },
  "appForgetPassIndex": {
    navTitle: "Passwort vergessen",
    formLabel1: "Anmelde- Mailbox",
    formLabel2: "Bestätigungscode",
    formLabel3: "Neues Passwort",
    formLabel4: "Passwort bestätigen",
    formHint1: "Bitte geben Sie Ihre Anmelde- E - Mail ein",
    formHint2: "Bitte geben Sie ein neues Passwort ein",
    formHint3: "Bitte geben Sie den Bestätigungscode ein",
    formHint4: "Bitte geben Sie Ihr Passwort zweimal ein",
    formOption: "Senden",
    sendOption: "Countdown:",
    optionsBtn: "Bestätigen",
  },
  'footer': {
    home: "Store",
    project: "Produkt",
    info: "Nachricht",
    order: "Bestellung",
    user: "Mein",
  },
  'setDrawer': {
    label1: "Mehrsprachige Einstellungen",
    label2: "Store-Registrierung",
    label3: "Store-Login",
    label4: "Kundenservice",
    label5: "Android-Anwendung herunterladen",
    label6: "iOS-Anwendung herunterladen",
    optionsBtn: "Abbrechen",
  },
  'appHomeIndex': {
    accountLabel1: 'Umsatz',
    accountLabel2: 'Verkehr',
    cardLabel1: 'Datenübersicht',
    cardLabel2: 'Datenbericht',
    cardLabel3: 'Betragsübersicht',
    ech1Title: 'Gesamtumsatz',
    ech2Title: 'Gesamtzahl der Bestellungen',
    ech3Title: 'Ladenverkehr',
    echBuoy1Title: 'Gesamtumsatz',
    echBuoy2Title: 'Gesamtzahl der Bestellungen',
    echBuoy3Title: 'Ladenverkehr',
    dataCardTitle1: 'Umsatz',
    dataCardTitle2: 'Verkehr',
    dataCardTitle3: 'Gesamtzahl der Bestellungen',
    dataCardTitle4: 'Gesamtbetrag der offenen Beträge',
    dataCardDayTags: 'Heute',
    dataYesterTags: "Daten von gestern",
    dataYesterTags1: "Anzahl der nicht geprüften Bestellungen",
    cardMenusLabel1: "Steuer",
    cardMenusLabel2: "Gespeicherter Wert",
    cardMenusLabel3: "Abhebung",
    cardMenusLabel4: "Einladung teilen",
    cardMenusLabel5: "Produktliste",
    cardMenusLabel6: "Bestellliste",
    cardMenusLabel7: "Geschäftsbericht",
    taxPopupTitle: "Derzeit müssen Steuern gezahlt werden:",
    popupBtn: "Gehen Sie und zahlen Sie Steuern",
    cardStateLabel1: "Guthaben",
    cardStateLabel2: "Abhebung",
    cardStateLabel3: "Einkommen",
    cardStateLabel4: "Team",
    cardSellHotTitle: "Top 10 der meistverkauften Artikel",
    salesLabel: "Verkäufe:",
  },
  'appSetLanguage': {
    navTitle: "Mehrsprachige Einstellungen",
    affirm: "Bestätigen",
  },
  "appServiceIndex": {
    navTitle: "Kundenservice",
    customerService: "Exklusiver Kundenservice",
    helloTitle: "Hallo",
    welcomeTitle: "Ich bin Ihr<, Sie können mich über folgende Kanäle kontaktieren",
    iconInformation: "Bitte wählen Sie eine Kommunikationssoftware für die Beratung",
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: "Guthaben",
    blanceOption1: "Abheben",
    blanceOption2: "Wert speichern",
    incomeLabel1: "Kumulatives Einkommen",
    incomeLabel2: "Kumulative Entnahmen",
    listLabel1: "Informationen speichern",
    listLabel2: "Einstellungen für Abhebungsmethode",
    listLabel3: "Fondsaufzeichnung",
    listLabel4: "Geschäftsbericht",
    listLabel5: "Rabattaktivität",
    listLabel7: "Darlehen",
    listLabel8: "Ins Einkaufszentrum gehen",
    listLabel9: "Anwendung herunterladen",
    listLabelLast: "Beenden",
  },
  'downloadAppIndex': {
    navTitle: "Herunterladen",
    downloadAndroidApp: "Android-Download",
    downloadIosApp: "IOS- Download",
  },
  "appDiscountActivity": {
    navTitle: 'Rabattaktivität',
    popupLabel1: 'Betrag',
    popupPlace1: 'Bitte geben Sie den Betrag ein',
    popupLabel2: 'Rabatt',
    popupPlace2: 'Bitte geben Sie den Rabatt ein',
    popupOption: 'Bestätigen',
    listLabel1: 'Erforderlicher Betrag:',
    listLabel2: 'Rabattverhältnis:',
    listLabel3: 'Rabattzeit:',
    dialogTitle: "Bestätigen Sie, ob Sie löschen möchten!",
    dialogAffirm: 'Bestätigen',
    dialogClose: "Abbrechen",
  },
  "appInviteIndex": {
    navTitle: "Neue Aktivitäten einladen",
    headerTitle: "Freunde einladen, um Belohnungen zu verdienen",
    copyOption: "kopieren",
    copySuccess: "Kopieren erfolgreich",
    inviteTitle: "Gesamtzahl der Einladungen",
    inviteLabel1: "Anzahl der eingeladenen Personen:",
    inviteLabel2: "Gesamtprovision:",
    ruleTitle: "Einladungsregel",
    ruleHint1: "1. Der Einladende kann Freunde einladen, die Registrierung abzuschließen, indem er den Werbelink teilt, und die eingeladenen Freunde werden mit Ihnen in Verbindung gebracht;",
    ruleHint2: "2. Nachdem Sie Benutzer eingeladen haben, die Zahlung abzuschließen, können Sie einen Anteil an ihren Gewinnen erhalten;",
    ruleHint3: "3. Es werden nur Einladungsbelohnungen der dritten Ebene unterstützt, und das Belohnungsverhältnis beträgt: <%, >%, @%;",
  },
  "appInviteUserRecordIndex": {
    navTitle: "Benutzerdatensatz einladen",
    recordTitle1: "Gesamtzahl der Benutzer",
    recordTitle2: "Ebene 1",
    recordTitle3: "Ebene 2",
    recordTitle4: "Ebene 3",
    tabLabel1: "Alle",
    tabLabel2: "Ebene 1",
    tabLabel3: "Zweite Ebene",
    tabLabel4: "Ebene 3",
    listLabel1: "Ebene:",
    listLabel2: "Gewinn:",
  },
  "appInviteIncomeRecordIndex": {
    navTitle: "Einladungsumsatzdatensatz",
    recordTitle1: "Gesamtumsatz",
    recordTitle2: "Ebene 1",
    recordTitle3: "Ebene 2",
    recordTitle4: "Ebene 3",
    tabLabel1: "Alle",
    tabLabel2: "Ebene 1",
    tabLabel3: "Zweite Ebene",
    tabLabel4: "Ebene 3",
    listLabel1: "Gewinn:",
    listLabel2: "Anteil:",
  },
  "appWithdrawIndex": {
    navTitle: "Auszahlung",
    currencyType1: "Fiat-Währung",
    currencyType2: "Virtuelle Währung",
    formLabel1: "Währungstyp:",
    formPlace1: "Bitte Währungstyp auswählen",
    formLabel2: "Währung auswählen:",
    formPlace2: "Bitte Währungstyp auswählen",
    formLabel3: "Auszahlungsbetrag:",
    formPlace3: "Bitte Auszahlungsbetrag eingeben",
    formLabel4: "Auszahlungspasswort:",
    formPlace4: "Bitte Auszahlungspasswort eingeben:",
    balanceLabel: "Aktueller Kontostand:",
    amountLabel: "Tatsächlicher Eingang:",
    optionsBtn: "Senden",
    dialogClose: "Abbrechen",
    dialogAffirm: "Bestätigen",
    currencySelectError: "Bitte Währungstyp auswählen",
    currencyError: "Bitte Währung auswählen",
    affirmDialogDialogTitle1: "Sie haben keinen Zahlungspasswort. Möchten Sie es einrichten?",
    affirmDialogDialogTitle2: "Sie haben den Auszahlungskanal nicht eingerichtet. Möchten Sie ihn einrichten?",
    affirmDialogBtn2: "Abbrechen",
    affirmDialogBtn1: "Bestätigen",
  },
  'appSetWithdrawalIndex': {
    navTitle: "Einstellung der Auszahlungsmethode",
    currencyType1: "Fiat-Währung",
    currencyType2: "Virtuelle Währung",
    formLabel1: "Währungstyp:",
    formPlace1: "Bitte Währungstyp auswählen",
    formLabel2: "Währung auswählen:",
    formPlace2: "Bitte Währungstyp auswählen",
    addressLabel: "Adresse:",
    addressPlace: "Bitte geben Sie die Adresse für virtuelle Währung ein",
    hintLabel: "Wichtiger Hinweis",
    hintValue: "Nur ¥-Vermögenswerte übertragen",
    optionsBtn: "Senden",
    dialogClose: "Abbrechen",
    dialogAffirm: "Bestätigen",
    currencySelectError: "Bitte Währungstyp auswählen",
    currencyError: "Bitte Währung auswählen",
    currencyFail: "Noch nicht geöffnet",
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: "richtiger Name",
    formPlace1: "Bitte geben Sie Ihren richtigen Namen ein",
    formLabel2: "E-Mail",
    formPlace2: "Bitte geben Sie Ihre E-Mail ein",
    formLabel3: "Mobiltelefonnummer",
    formPlace3: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
    formLabel4: "PIX-Typ",
    formPlace4: "Bitte wählen Sie den PIX-Typ",
    formLabel5: "PIX-Konto",
    formPlace5: "Bitte geben Sie Ihre PIX - Kontonummer ein",
    formLabel6: "Name der Bank",
    formPlace6: "Bitte geben Sie den Namen der Bank ein",
    formLabel7: "Bankkonto",
    formPlace7: "Bitte geben Sie das Bankkonto ein",
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: "Land/ Region",
    formPlace1: "Bitte geben Sie Land/ Region ein",
    formLabel2: "Name",
    formPlace2: "Bitte geben Sie einen Namen ein",
    formLabel3: "Nachname",
    formPlace3: "Bitte geben Sie Ihren Nachnamen ein",
    formLabel4: "Swift",
    formPlace4: "Bitte geben Sie Swift ein",
    formLabel5: "Name der Bank",
    formPlace5: "Bitte geben Sie den Namen der Bank ein",
    formLabel6: "IBAN",
    formPlace6: "Bitte geben Sie IBAN ein",
    formLabel7: "Mobiltelefonnummer",
    formPlace7: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: "Land/Region",
    formPlace1: "Bitte geben Sie Land/Region ein",
    formLabel2: "Name",
    formPlace2: "Bitte geben Sie einen Namen ein",
    formLabel3: "Nachname",
    formPlace3: "Bitte geben Sie Ihren Nachnamen ein",
    formLabel4: "Swift",
    formPlace4: "Bitte geben Sie Swift ein",
    formLabel5: "ACH",
    formPlace5: "Bitte geben Sie ACH ein",
    formLabel6: "Name der Bank",
    formPlace6: "Bitte geben Sie den Namen der Bank ein",
    formLabel7: "Bankkonto",
    formPlace7: "Bitte geben Sie das Bankkonto ein",
    formLabel8: "Provinz oder Staat",
    formPlace8: "Bitte geben Sie die Provinz oder den Staat ein",
    formLabel9: "Mobiltelefonnummer",
    formPlace9: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: "Land/Region",
    formPlace1: "Bitte geben Sie Land/Region ein",
    formLabel2: "Name",
    formPlace2: "Bitte geben Sie einen Namen ein",
    formLabel3: "Nachname",
    formPlace3: "Bitte geben Sie Ihren Nachnamen ein",
    formLabel4: "Bankleitzahl",
    formPlace4: "Bitte geben Sie die Bankleitzahl ein",
    formLabel5: "Name der Bank",
    formPlace5: "Bitte geben Sie den Namen der Bank ein",
    formLabel6: "Bankkonto",
    formPlace6: "Bitte geben Sie das Bankkonto ein",
    formLabel7: "Mobiltelefonnummer",
    formPlace7: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: "Land/Region",
    formPlace1: "Bitte geben Sie Land/Region ein",
    formLabel2: "Name",
    formPlace2: "Bitte geben Sie einen Namen ein",
    formLabel3: "Nachname",
    formPlace3: "Bitte geben Sie Ihren Nachnamen ein",
    formLabel4: "zengin",
    formPlace4: "Bitte geben Sie zengin ein",
    formLabel5: "Name der Bank",
    formPlace5: "Bitte geben Sie den Namen der Bank ein",
    formLabel6: "Bankkonto",
    formPlace6: "Bitte geben Sie das Bankkonto ein",
    formLabel7: "Provinz oder Staat",
    formPlace7: "Bitte geben Sie die Provinz oder den Staat ein",
    formLabel8: "Mobiltelefonnummer",
    formPlace8: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: "Land/Region",
    formPlace1: "Bitte geben Sie Land/Region ein",
    formLabel2: "Name",
    formPlace2: "Bitte geben Sie einen Namen ein",
    formLabel3: "Nachname",
    formPlace3: "Bitte geben Sie Ihren Nachnamen ein",
    formLabel4: "BSB",
    formPlace4: "Bitte geben Sie BSB ein",
    formLabel5: "Name der Bank",
    formPlace5: "Bitte geben Sie den Namen der Bank ein",
    formLabel6: "Bankkonto",
    formPlace6: "Bitte geben Sie das Bankkonto ein",
    formLabel7: "Provinz oder Staat",
    formPlace7: "Bitte geben Sie die Provinz oder den Staat ein",
    formLabel8: "Mobiltelefonnummer",
    formPlace8: "Bitte geben Sie Ihre Mobiltelefonnummer ein",
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: 'Name',
    formPlace1: 'Bitte geben Sie einen Namen ein',
    formLabel2: 'Nachname',
    formPlace2: 'Bitte geben Sie Ihren Nachnamen ein',
    formLabel3: 'Name der Bank',
    formPlace3: 'Bitte geben Sie den Namen der Bank ein',
    formLabel4: 'Bankkonto',
    formPlace4: 'Bitte geben Sie das Bankkonto ein',
    formLabel5: 'Mobiltelefonnummer',
    formPlace5: 'Bitte geben Sie Ihre Mobiltelefonnummer ein',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: 'Bankname',
    formPlace1: 'Bitte geben Sie den Banknamen ein',
    formLabel2: 'Konto',
    formPlace2: 'Bitte geben Sie Ihr Konto ein',
    formLabel3: 'Clabe-Konto',
    formPlace3: 'Bitte geben Sie Ihr Clabe-Konto ein',
    formLabel4: 'Mobiltelefonnummer',
    formPlace4: 'Bitte geben Sie Ihre Mobiltelefonnummer ein',
  },
  'appStatisticsReportIndex': {
    navTitle: "Betriebsbericht",
    cardLabel1: "Gesamtumsatz",
    cardLabel2: "Gesamtkaufbetrag",
    cardLabel3: "Gesamtgewinn",
    cardLabel4: "Gesamtzahl der Bestellungen",
    listLabel1: "Zeit:",
    listLabel2: "Bestellung:",
    listLabel3: "Umsatz:",
    listLabel4: "Kaufbetrag:",
    profitLabel: "Gewinn",
    searchDateTitle: "Dokumentzeit",
    searchIntervalTitle: "Intervallzeit",
    searchStartTimePlace: "Startzeit",
    searchEndTimePlace: "Endzeit",
    searchOptions1: "Zurücksetzen",
    searchOptions2: "Bestätigen",
    searchDateAffirm: "Bestätigen",
    searchDateClose: "Abbrechen",
    searchIntervalLabel1: "Alle",
    searchIntervalLabel2: "Heute",
    searchIntervalLabel3: "Gestern",
    searchIntervalLabel4: "Diese Woche",
    searchIntervalLabel5: "Diesen Monat",
    searchIntervalLabel6: "Dieses Jahr",
    searchIntervalOptions: "Abbrechen",
  },
  "appAmountRechargeIndex": {
    navTitle: "Gespeicherter Wert",
    currencyType1: "Fiat- Währung",
    currencyType2: "Virtuelle Währung",
    formLabel1: "Währungstyp:",
    formPlace1: "Bitte Währungstyp auswählen",
    formLabel2: "Währung auswählen:",
    formPlace2: "Bitte Währungstyp auswählen",
    formLabel3: "Gespeicherter Wertbetrag:",
    formPlace3: "Bitte geben Sie den gespeicherten Wertbetrag ein",
    formLabel4: "Auflademethode",
    formPlace4: "Bitte wählen Sie die Speichermethode:",
    balanceLabel: "Aktueller Kontostand:",
    amountLabel: "Tatsächlicher Eingang:",
    optionsBtn: "Senden",
    dialogClose: "Abbrechen",
    dialogAffirm: "Bestätigen",
    currencySelectError: "Bitte Währungstyp auswählen",
    currencyError: "Bitte Währung auswählen",
    successCopy: "Erfolgreich kopiert",
    hintLabel: "Wichtige Erinnerung:",
    hintValue: 'Nur die Übertragung von ¥-Vermögenswerten ist zulässig',
    legalHint: 'Bitte kontaktieren Sie den Kundenservice!'

  },
  'appShopInfoIndex': {
    navTitle: "Informationen zum Shop",
    navEditText: "Bearbeiten",
    formLabel1: "Bild zum Shop",
    formLabel2: "Name des Shops",
    formLabel3: "Anmelde-E-Mail",
    formLabel4: "Einladungscode",
    formLabel5: "Anmeldekennwort",
    formLabel6: "Zahlungskennwort",
    formLabel7: "Beschreibungsnachricht",
    formLabel8: "Hintergrundbild speichern",
    formLabel9: "Hintergrundbild vermarkten",
    formLabel10: "Zertifikatsinformationen",
    formHint1: "Vorderseite des Dokuments",
    formHint2: "Rückseite des Zertifikats",
    fileFail: "Bitte laden Sie Bilder und Format hoch",
    optionsBtn: "Senden",
  },
  'appShopInfoEdit': {
    navTitle: "Store-Informationseditor",
    formLabel1: "Bild zum Shop",
    formLabel2: "Name des Shops",
    formLabel3: "Anmelde-E-Mail",
    formLabel4: "Einladungscode",
    formLabel7: "Beschreibungsnachricht",
    formLabel8: "Hintergrundbild speichern",
    formLabel9: "Hintergrundbild vermarkten",
    fileFail: "Bitte laden Sie Bilder und Format hoch",
    optionsBtn: "Senden",
  },
  'appChangeLoginPass': {
    navTitle: "Anmeldekennwort ändern",
    formLabel1: "Altes Kennwort",
    formLabel2: "Neues Kennwort",
    formLabel3: "Bestätigungscode",
    formHint1: "Bitte geben Sie das alte Kennwort ein",
    formHint2: "Bitte geben Sie ein neues Kennwort ein",
    formHint3: "Bitte geben Sie den Bestätigungscode ein",
    formOption: "Senden",
    sendOption: "Countdown:",
    optionsBtn: "Bestätigen",
  },
  'appChangePayPass': {
    navTitle: "Zahlungspasswort ändern",
    formLabel1: "Zahlungspasswort",
    formLabel2: "Verifizierungscode",
    formHint1: "Bitte Zahlungspasswort eingeben",
    formHint2: "Bitte Verifizierungscode eingeben",
    formOption: "Senden",
    sendOption: "Countdown:",
    optionsBtn: "Bestätigen",
  },
  "appFundingRecordsIndex": {
    navTitle: "Fondsdatensatz",
    searchRecordsTitle: "Fondstyp",
    searchDateTitle: "Zeitauswahl",
    searchFundingLabel1: "Alle Fonds",
    searchFundingLabel2: "Einzahlungstyp",
    searchFundingLabel3: "Auszahlungsdatensatz",
    searchOptions1: "Zurücksetzen",
    searchOptions2: "Bestätigen",
    searchDateAffirm: "Bestätigen",
    searchDateClose: "Abbrechen",
    stareTimePlace: "Startzeit",
    endTimePlace: "Endzeit",
    typeAll: "Alle",
  },
  "appProjectIndex": {
    navTitle: "Produktliste",
    searchPlace: "Bitte geben Sie den Produktnamen ein, nach dem Sie suchen möchten",
    shelvesLabel: "obere und untere Regale",
    brandPlace: "Bitte wählen Sie eine Marke",
    classPlace: "Bitte wählen Sie eine Kategorie",
    searchOptions1: "Zurücksetzen",
    searchOptions2: "Bestätigen",
    shelvesTag1: "Alle",
    shelvesTag2: "in Regalen",
    shelvesTag3: "nicht in Regalen",
    salesLabel: "Verkäufe:",
    dialogAffirm: "Bestätigen",
    dialogClose: "Abbrechen",
    listLabel: "Obere und untere Regale:",
  },
  "appProjectLibraryIndex": {
    navTitle: "Produktbibliothek",
    searchPlace: "Bitte geben Sie den Produktnamen ein, nach dem Sie suchen möchten",
    brandPlace: "Bitte wählen Sie eine Marke",
    classPlace: "Bitte wählen Sie eine Kategorie",
    searchOptions1: "Zurücksetzen",
    searchOptions2: "Bestätigen",
    salesLabel: "Verkäufe:",
    dialogAffirm: "Bestätigen",
    dialogClose: "Abbrechen",
    productPackageLabel1: "Product Package",
    productPackageLabel2: '(Quick Add Product)',

    selectAll: 'Multiple Choice',
    selectHint: 'Unterstützt nur bis zu 50 Produkte',
    submitOptions: 'Submit',
    dialogHint: 'Sind Sie sicher, dass Sie dieses Produkt in einer Batch-Liste auflisten?',
  },
  'appProductPackageIndex': {
    navTitle: 'Product Package',
    listLabel1: 'Empfohlene Sternebewertung:',
    listLabel2: 'Synchronization status:',
    listLabel3: "Produktmenge:",
    optionsBtn: 'Sync',
    dialogHint: 'Sind Sie sicher, dass Sie dieses Produktpaket verwenden möchten?',
    dialogOptions1: 'Abbrechen',
    dialogOptions2: 'Bestätigen',
    messageToast: 'Einreichung erfolgreich, Produktinformationssynchronisierung läuft...',
  },
  'appProductPackageList': {
    navTitle: 'Product Package Details',
    salesLabel: 'Verkauf:',
  },
  "appProductFirstIndex": {
    navTitle: 'Produktinformationen',
    nextStep: 'Nächster Schritt',
    label1: 'Produktname:',
    label2: 'Produktmarke:',
    label3: 'Produktkategorie:',
    label4: 'Mindestkaufpreis:',
    label5: 'Mindestverkaufspreis:',
    label6: 'Mindestmarktpreis:',
    label7: 'Titelbild:',
    label8: 'Produktbild:',

  },
  'appProductNextStepIndex': {
    navTitle: "Produktspezifikationen",
    previous: "vorheriger Schritt",
    navBtn: "Senden",
    label1: "Einkaufspreis:",
    placeHint: "Bitte eingeben",
    label2: "Verkaufspreis:",
    label3: "Marktpreis:",
    specLabel: "Spezifikationsattribut:",
    optionBtn: "Bestätigen",
    validateMessage1: "Der ursprüngliche Preis kann nicht niedriger sein als der Einkaufspreis",
    validateMessage2: "Der ursprüngliche Preis kann nicht niedriger sein als der aktuelle Verkaufspreis",
    validateMessage3: "Der Verkaufspreis kann nicht niedriger sein als der Einkaufspreis",
    validateMessage4: "Der Verkaufspreis kann nicht höher sein als der ursprüngliche Preis",
    shelvesTitle: "Möchten Sie die Regale be- oder entladen?",
    shelvesOptions1: "Nein",
    shelvesOptions2: "Ja",
  },
  "appOrderIndex": {
    tagsLabel1: "Alle",
    orderTotalNumber: "Gesamtbestellnummer",
    listLabel1: "Bestelldatum:",
    listLabel2: "Gesamtumsatz:",
    listLabel3: "Produktmenge:",
    listLabel4: "Bestellstatus:",
    listLabel5: "Zahlungsstatus:",
    listHint: "Kauf",
    searchOptionBtn: "Suchen",
    searchPlace: "Bitte Suchinhalt eingeben",
    searchDateStartPlace: "Startzeit",
    searchDateEndPlace: "Endzeit",
    searchDateAffirm: "Bestätigen",
    searchDateClose: "Abbrechen",
    searchTypePayTitle: "Zahlungsstatus",
    payTags1: "Alle",
    payTags2: "Ausstehende Zahlung",
    payTags3: "Bezahlt",
    searchTypeOrderTitle: "Bestellstatus",
    searchOptions1: "Zurücksetzen",
    searchOptions2: 'Bestätigen',
    purDialogTitle: 'Alles kaufen',
    purDialogLabel1: 'Kaufbetrag:',
    purDialogLabel2: 'Verkaufsbetrag:',
    purDialogLabel3: 'Gewinnbetrag:',
    purOptions: "Bestätigen",
    purRowTitle: 'Kaufen',
    purRowLabel1: 'Kaufbetrag:',
    purRowLabel2: 'Verkaufsbetrag:',
    purRowLabel3: 'Gewinnbetrag:',

  },
  "appOrderDetailIndex": {
    navTitle: 'Bestelldetails',
    detailInfoLabel1: 'Bestellnummer:',
    detailInfoLabel2: 'Bestellzeit:',
    detailInfoLabel3: 'Zahlungsmethode:',
    detailInfoLabel4: 'Bestellstatus:',
    detailInfoLabel5: 'Zahlungsstatus:',
    productLabel1: 'Produktinformationen',
    productAttributesLabel: 'Produktattribute:',
    productAccountLabel1: 'Umsatz:',
    productAccountLabel2: 'Kaufbetrag:',
    countInfoLabel1: 'Gesamtumsatz:',
    countInfoLabel2: 'Gesamtkaufbetrag:',
    countInfoLabel3: 'Gesamtgewinn:',
    countInfoLabel4: 'Gesamtmenge:',
    countInfoLabel5: 'Rabattverhältnis:',
  },
  "appOrderDetailLogistics": {
    navTitle: 'Auftragslogistik',
    logisticsLabel: 'Auftrag',
    listLabel1: "Name:",
    listLabel2: "Telefon:",
    listLabel3: "Postleitzahl:",
    listLabel4: "Adresse:",
  },
  "appOrderDetailRecord": {
    navTitle: "Bestelldatensatz",
    logisticsLabel: "Bestellung",
    recordEnumLabel1: "Kunde erstellt Bestellung",
    recordEnumLabel2: "Zahlungsauftrag des Kunden",
    recordEnumLabel3: "Shop-Bestellung",
    recordEnumLabel4: "Bestellung wird versandt",
    recordEnumLabel5: "Benutzer bestätigt Erhalt",
    recordEnumLabel6: "Systemrückzahlung kommt an",

  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: "Versionsupgrade, bitte bestätigen?",
    loadingTitle: "Wird heruntergeladen...",
    systemUpgrade: "Systemupgrade",
    upgradeImmediately: "Bestätigen",
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "Wird geladen",
    finishedText: "Keine",
    loadingText: "Wird geladen",
    nullText: "Noch keine Daten",
    loadingFinished: "Laden abgeschlossen",
    listNullText: "Keine weiteren",
    pullIngText: "Zum Aktualisieren nach unten ziehen",
    loosingText: "Zum Aktualisieren loslassen",
    pullingSuccess: "Pulldown-Laden erfolgreich",
    webSocketSuccess: "Sie haben eine neue Nachricht",
    imLinkSuccess: "IM-Dienstverbindung erfolgreich! ",
    imNonsupport: "Dieser Browser unterstützt kein WebSocket",
    imLinkLoading: "IM wird verknüpft, bitte warten …",
    imLinkErrorLoading: "IM - Verbindung fehlgeschlagen, bitte aktualisieren Sie die Seite oder wenden Sie sich an den Entwickler!",
    dialogHint: 'Haben Sie bereits ein Konto?',
    dialogClose: 'Registrieren',
    dialogAffirm: 'Login',
  },
};
export default obj;