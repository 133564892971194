// 阿拉伯语

let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: 'رسالة',
    IMErrorMessage: 'فشل اتصال المراسلة الفورية، انقر للمحاولة مرة أخرى! ',
  },
  "appWebSocketDetails": {
    sendOption: 'يرسل',
    send: 'يرسل',
    fileFail: 'يرجى تحميل الصور وتنسيقها',
    IMportError: 'غير متصل حاليا، يرجى المحاولة مرة أخرى! ',
    IMErrorMessage: 'فشل اتصال المراسلة الفورية، يرجى المحاولة مرة أخرى!',
    retryBtn: 'أعد المحاولة',
  },
  "appLoginIndex": {
    navTitle: 'تسجيل الدخول',
    projectName: 'إدارة المتجر',
    formLabel1: 'بريد إلكتروني',
    formLabel2: 'كلمة المرور',
    placeholder: 'تفضل',
    hint: "إذا كنت لا تملك حساب،",
    clickRegister: 'انقر للتسجيل',
    forgetPass: 'هل نسيت كلمة السر',
    optionBtn: 'تسجيل الدخول',
    validateError: 'يرجى إكمال الإدخال! ',
  },
  "appForgetPassIndex": {
    navTitle: 'هل نسيت كلمة السر',
    formLabel1: 'صندوق بريد تسجيل الدخول',
    formLabel2: 'رمز التحقق',
    formLabel3: 'كلمة المرور الجديدة',
    formLabel4: 'تأكيد كلمة المرور',
    formHint1: 'الرجاء إدخال البريد الإلكتروني لتسجيل الدخول الخاص بك',
    formHint2: 'الرجاء إدخال كلمة مرور جديدة',
    formHint3: 'الرجاء إدخال رمز التحقق',
    formHint4: "الرجاء إدخال كلمة المرور مرتين",
    formOption: "إرسال",
    sendOption: "العد التنازلي:",
    optionsBtn: "تأكيد",
  },
  'footer': {
    home: "بيت",
    project: "المنتج",
    info: "رسالة",
    order: "أمر",
    user: "بلدي",
  },
  'setDrawer': {
    label1: "إعدادات متعددة اللغات",
    label2: "تسجيل المتجر",
    label3: "تسجيل الدخول إلى المتجر",
    label4: "خدمة العملاء",
    label5: "تنزيل تطبيق Android",
    label6: "تنزيل تطبيق ios",
    optionsBtn: 'إلغاء',
  },
  'appHomeIndex': {
    accountLabel1: "المبيعات",
    accountLabel2: "حركة المرور",
    cardLabel1: "نظرة عامة على البيانات",
    cardLabel2: "تقرير البيانات",
    cardLabel3: "نظرة عامة على المبلغ",
    ech1Title: "إجمالي المبيعات",
    ech2Title: "إجمالي عدد الطلبات",
    ech3Title: "حركة مرور المتجر",
    echBuoy1Title: "إجمالي المبيعات",
    echBuoy2Title: "إجمالي عدد الطلبات",
    echBuoy3Title: "حركة مرور المتجر",
    dataCardTitle1: "المبيعات",
    dataCardTitle2: "حركة المرور",
    dataCardTitle3: "إجمالي عدد الطلبات",
    dataCardTitle4: "إجمالي المبلغ المستحق",
    dataCardDayTags: "اليوم",
    dataYesterTags: "بيانات الأمس",
    dataYesterTags1: "كمية الطلبات التي لم يتم التحقق منها",
    cardMenusLabel1: "الضريبة",
    cardMenusLabel2: "القيمة المخزنة",
    cardMenusLabel3: "الانسحاب",
    cardMenusLabel4: "مشاركة الدعوة",
    cardMenusLabel5: "قائمة المنتجات",
    cardMenusLabel6: "قائمة الطلبات",
    cardMenusLabel7: "تقرير الأعمال",
    taxPopupTitle: 'في الوقت الحالي بحاجة إلى دفع الضرائب:',
    popupBtn: "اذهب وادفع الضرائب",
    cardStateLabel1: "الرصيد",
    cardStateLabel2: "الانسحاب",
    cardStateLabel3: "الدخل",
    cardStateLabel4: "الفريق",
    cardSellHotTitle: "أكثر 10 عناصر مبيعًا",
    salesLabel: 'المبيعات:',
  },
  'appSetLanguage': {
    navTitle: "إعدادات متعددة اللغات",
    affirm: 'يؤكد',
  },
  "appServiceIndex": {
    navTitle: "خدمة العملاء",
    customerService: "خدمة العملاء الحصرية",
    helloTitle: "مرحبا",
    welcomeTitle: "أنا لك<، يمكنك التواصل معي عبر القنوات التالية",
    iconInformation: "يرجى تحديد برنامج الاتصالات للتشاور",
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: "الرصيد",
    blanceOption1: "سحب",
    blanceOption2: "قيمة المتجر",
    incomeLabel1: "الدخل التراكمي",
    incomeLabel2: "السحوبات التراكمية",
    listLabel1: "تخزين المعلومات",
    listLabel2: "إعدادات طريقة السحب",
    listLabel3: "سجل الصندوق",
    listLabel4: "تقرير الأعمال",
    listLabel5: "نشاط الخصم",
    listLabel7: "قرض",
    listLabel8: "اذهب إلى المركز التجاري",
    listLabel9: "تنزيل التطبيق",
    listLabelLast: "خروج",
  },
  'downloadAppIndex': {
    navTitle: "تنزيل",
    downloadAndroidApp: "تنزيل Android",
    downloadIosApp: "تنزيل IOS",
  },
  "appDiscountActivity": {
    navTitle: "نشاط الخصم",
    popupLabel1: "المبلغ",
    popupPlace1: "الرجاء إدخال المبلغ",
    popupLabel2: "الخصم",
    popupPlace2: "الرجاء إدخال الخصم",
    popupOption: "تأكيد",
    listLabel1: 'المبلغ المطلوب:',
    listLabel2: "نسبة الخصم:",
    listLabel3: "وقت الخصم:",
    dialogTitle: "تأكيد ما إذا كنت تريد الحذف!",
    dialogAffirm: "تأكيد",
    dialogClose: "إلغاء",
  },
  "appInviteIndex": {
    navTitle: "دعوة لأنشطة جديدة",
    headerTitle: "قم بدعوة الأصدقاء للحصول على مكافآت",
    copyOption: "نسخ",
    copySuccess: "تم النسخ بنجاح",
    inviteTitle: "إجمالي عدد الدعوات",
    inviteLabel1: "عدد الأشخاص المدعوين:",
    inviteLabel2: "إجمالي العمولة:",
    ruleTitle: "قاعدة الدعوة",
    ruleHint1: ' 1. يمكن للمدعو دعوة الأصدقاء لإكمال التسجيل من خلال مشاركة رابط الترويج، وسيتم ربط الأصدقاء المدعوين بك؛',
    ruleHint2: ' 2. بعد دعوة المستخدمين لإكمال الدفع، يمكنك الحصول على حصة من أرباحهم;',
    ruleHint3: ' 3. يتم دعم مكافآت الدعوة من المستوى الثالث فقط، ونسبة المكافأة هي: <%, >%, @%; ',
  },
  "appInviteUserRecordIndex": {
    navTitle: "دعوة سجل المستخدم",
    recordTitle1: "إجمالي عدد المستخدمين",
    recordTitle2: "المستوى 1",
    recordTitle3: "المستوى 2",
    recordTitle4: "المستوى 3",
    tabLabel1: "الكل",
    tabLabel2: "المستوى 1",
    tabLabel3: "المستوى الثاني",
    tabLabel4: "المستوى 3",
    listLabel1: "المستوى:",
    listLabel2: 'الربح:',
  },
  "appInviteIncomeRecordIndex": {
    navTitle: "سجل إيرادات الدعوة",
    recordTitle1: "إجمالي الإيرادات",
    recordTitle2: "المستوى 1",
    recordTitle3: "المستوى 2",
    recordTitle4: "المستوى 3",
    tabLabel1: "الكل",
    tabLabel2: "المستوى 1",
    tabLabel3: "المستوى الثاني",
    tabLabel4: "المستوى 3",
    listLabel1: 'الربح:',
    listLabel2: 'النسبة:',
  },
  "appWithdrawIndex": {
    navTitle: "الانسحاب",
    currencyType1: "العملة الورقية",
    currencyType2: "العملة الافتراضية",
    formLabel1: 'نوع العملة:',
    formPlace1: "الرجاء تحديد نوع العملة",
    formLabel2: "اختر العملة:",
    formPlace2: "الرجاء تحديد نوع العملة",
    formLabel3: "مبلغ السحب:",
    formPlace3: "الرجاء إدخال مبلغ السحب",
    formLabel4: 'كلمة مرور السحب:',
    formPlace4: "الرجاء إدخال كلمة مرور السحب:",
    balanceLabel: 'الرصيد الحالي:',
    amountLabel: 'الوصول الفعلي:',
    optionsBtn: "إرسال",
    dialogClose: "إلغاء",
    dialogAffirm: "تأكيد",
    currencySelectError: "الرجاء تحديد نوع العملة",
    currencyError: "الرجاء تحديد العملة",
    affirmDialogDialogTitle1: 'لم تقم بتعيين كلمة مرور الدفع. هل تريد إعداده؟ ',
    affirmDialogDialogTitle2: "لم تقم بتعيين قناة السحب، هل تريد تعيينها؟",
    affirmDialogBtn2: "إلغاء",
    affirmDialogBtn1: "تأكيد",
  },
  'appSetWithdrawalIndex': {
    navTitle: "إعداد طريقة السحب",
    currencyType1: "العملة الورقية",
    currencyType2: "العملة الافتراضية",
    formLabel1: 'نوع العملة:',
    formPlace1: "الرجاء تحديد نوع العملة",
    formLabel2: "اختر العملة:",
    formPlace2: "الرجاء تحديد نوع العملة",
    addressLabel: 'العنوان:',
    addressPlace: "الرجاء إدخال عنوان العملة الافتراضية",
    hintLabel: "نصيحة هامة",
    hintValue: "نقل الأصول ¥ فقط",
    optionsBtn: "إرسال",
    dialogClose: "إلغاء",
    dialogAffirm: "تأكيد",
    currencySelectError: "الرجاء تحديد نوع العملة",
    currencyError: "الرجاء تحديد العملة",
    currencyFail: "لم يتم فتحه بعد",
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: "الاسم الحقيقي",
    formPlace1: "الرجاء إدخال اسمك الحقيقي",
    formLabel2: "البريد الإلكتروني",
    formPlace2: "الرجاء إدخال بريدك الإلكتروني",
    formLabel3: "رقم الهاتف المحمول",
    formPlace3: "الرجاء إدخال رقم هاتفك المحمول",
    formLabel4: "نوع PIX",
    formPlace4: "الرجاء تحديد نوع PIX",
    formLabel5: "حساب PIX",
    formPlace5: "الرجاء إدخال رقم حساب PIX الخاص بك",
    formLabel6: "اسم البنك",
    formPlace6: "الرجاء إدخال اسم البنك",
    formLabel7: "الحساب البنكي",
    formPlace7: "الرجاء إدخال الحساب البنكي",
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: "البلد/المنطقة",
    formPlace1: "الرجاء إدخال البلد/المنطقة",
    formLabel2: "الاسم",
    formPlace2: "الرجاء إدخال اسم",
    formLabel3: "الاسم الأخير",
    formPlace3: "الرجاء إدخال اسمك الأخير",
    formLabel4: "سريع",
    formPlace4: "الرجاء إدخال كلمة Swift",
    formLabel5: "اسم البنك",
    formPlace5: "الرجاء إدخال اسم البنك",
    formLabel6: "إيبان",
    formPlace6: "الرجاء إدخال رقم iban",
    formLabel7: "رقم الهاتف المحمول",
    formPlace7: "الرجاء إدخال رقم هاتفك المحمول",
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: "البلد/المنطقة",
    formPlace1: "الرجاء إدخال البلد/المنطقة",
    formLabel2: "الاسم",
    formPlace2: "الرجاء إدخال اسم",
    formLabel3: "الاسم الأخير",
    formPlace3: "الرجاء إدخال اسمك الأخير",
    formLabel4: "سريع",
    formPlace4: "الرجاء إدخال كلمة Swift",
    formLabel5: "ach",
    formPlace5: "الرجاء إدخال ach",
    formLabel6: "اسم البنك",
    formPlace6: "الرجاء إدخال اسم البنك",
    formLabel7: "الحساب البنكي",
    formPlace7: "الرجاء إدخال الحساب البنكي",
    formLabel8: "المقاطعة أو الولاية",
    formPlace8: "الرجاء إدخال المقاطعة أو الولاية",
    formLabel9: "رقم الهاتف المحمول",
    formPlace9: "الرجاء إدخال رقم هاتفك المحمول",
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: "البلد/المنطقة",
    formPlace1: "الرجاء إدخال البلد/المنطقة",
    formLabel2: "الاسم",
    formPlace2: "الرجاء إدخال اسم",
    formLabel3: "الاسم الأخير",
    formPlace3: "الرجاء إدخال اسمك الأخير",
    formLabel4: "رمز البنك",
    formPlace4: "الرجاء إدخال رمز البنك",
    formLabel5: "اسم البنك",
    formPlace5: "الرجاء إدخال اسم البنك",
    formLabel6: "الحساب البنكي",
    formPlace6: "الرجاء إدخال الحساب البنكي",
    formLabel7: "رقم الهاتف المحمول",
    formPlace7: "الرجاء إدخال رقم هاتفك المحمول",
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: "البلد/المنطقة",
    formPlace1: "الرجاء إدخال البلد/المنطقة",
    formLabel2: "الاسم",
    formPlace2: "الرجاء إدخال اسم",
    formLabel3: "الاسم الأخير",
    formPlace3: "الرجاء إدخال اسمك الأخير",
    formLabel4: "زينجين",
    formPlace4: "الرجاء إدخال zengin",
    formLabel5: "اسم البنك",
    formPlace5: "الرجاء إدخال اسم البنك",
    formLabel6: "الحساب البنكي",
    formPlace6: "الرجاء إدخال الحساب البنكي",
    formLabel7: "المقاطعة أو الولاية",
    formPlace7: "الرجاء إدخال المقاطعة أو الولاية",
    formLabel8: "رقم الهاتف المحمول",
    formPlace8: "الرجاء إدخال رقم هاتفك المحمول",
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: "البلد/المنطقة",
    formPlace1: "الرجاء إدخال البلد/المنطقة",
    formLabel2: "الاسم",
    formPlace2: "الرجاء إدخال اسم",
    formLabel3: "الاسم الأخير",
    formPlace3: "الرجاء إدخال اسمك الأخير",
    formLabel4: 'BSB',
    formPlace4: "الرجاء إدخال BSB",
    formLabel5: "اسم البنك",
    formPlace5: "الرجاء إدخال اسم البنك",
    formLabel6: "الحساب البنكي",
    formPlace6: "الرجاء إدخال الحساب البنكي",
    formLabel7: "المقاطعة أو الولاية",
    formPlace7: "الرجاء إدخال المقاطعة أو الولاية",
    formLabel8: "رقم الهاتف المحمول",
    formPlace8: "الرجاء إدخال رقم هاتفك المحمول",
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: "الاسم",
    formPlace1: "الرجاء إدخال اسم",
    formLabel2: "الاسم الأخير",
    formPlace2: "الرجاء إدخال اسمك الأخير",
    formLabel3: "اسم البنك",
    formPlace3: "الرجاء إدخال اسم البنك",
    formLabel4: "الحساب البنكي",
    formPlace4: "الرجاء إدخال الحساب البنكي",
    formLabel5: "رقم الهاتف المحمول",
    formPlace5: "الرجاء إدخال رقم هاتفك المحمول",
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: "اسم البنك",
    formPlace1: "الرجاء إدخال اسم البنك",
    formLabel2: "الحساب",
    formPlace2: "الرجاء إدخال حسابك",
    formLabel3: "حساب clabe",
    formPlace3: "الرجاء إدخال حساب clabe الخاص بك",
    formLabel4: "رقم الهاتف المحمول",
    formPlace4: "الرجاء إدخال رقم هاتفك المحمول",
  },
  'appStatisticsReportIndex': {
    navTitle: "تقرير التشغيل",
    cardLabel1: "إجمالي المبيعات",
    cardLabel2: "إجمالي مبلغ الشراء",
    cardLabel3: "إجمالي الربح",
    cardLabel4: "إجمالي عدد الطلبات",
    listLabel1: 'الوقت:',
    listLabel2: "الطلب:",
    listLabel3: "المبيعات:",
    listLabel4: "مبلغ الشراء:",
    profitLabel: "الربح",
    searchDateTitle: "وقت المستند",
    searchIntervalTitle: "الفاصل الزمني",
    searchStartTimePlace: "وقت البدء",
    searchEndTimePlace: "وقت الانتهاء",
    searchOptions1: "إعادة التعيين",
    searchOptions2: "تأكيد",
    searchDateAffirm: "تأكيد",
    searchDateClose: "إلغاء",
    searchIntervalLabel1: "الكل",
    searchIntervalLabel2: "اليوم",
    searchIntervalLabel3: "أمس",
    searchIntervalLabel4: "هذا الأسبوع",
    searchIntervalLabel5: "هذا الشهر",
    searchIntervalLabel6: "هذا العام",
    searchIntervalOptions: "إلغاء",
  },
  "appAmountRechargeIndex": {
    navTitle: "القيمة المخزنة",
    currencyType1: "العملة الورقية",
    currencyType2: "العملة الافتراضية",
    formLabel1: 'نوع العملة:',
    formPlace1: "الرجاء تحديد نوع العملة",
    formLabel2: "اختر العملة:",
    formPlace2: "الرجاء تحديد نوع العملة",
    formLabel3: 'مبلغ القيمة المخزنة:',
    formPlace3: "الرجاء إدخال مبلغ القيمة المخزنة",
    formLabel4: "طريقة إعادة الشحن",
    formPlace4: "الرجاء تحديد طريقة التخزين:",
    balanceLabel: 'الرصيد الحالي:',
    amountLabel: 'الوصول الفعلي:',
    optionsBtn: "إرسال",
    dialogClose: "إلغاء",
    dialogAffirm: "تأكيد",
    currencySelectError: "الرجاء تحديد نوع العملة",
    currencyError: "الرجاء تحديد العملة",
    successCopy: "تم النسخ بنجاح",
    hintLabel: "تذكير مهم:",
    hintValue: "يُسمح فقط بنقل أصول ¥",
    legalHint: 'يرجى الاتصال بخدمة العملاء !'
  },
  'appShopInfoIndex': {
    navTitle: "تخزين المعلومات",
    navEditText: "تحرير",
    formLabel1: "تخزين الصورة",
    formLabel2: "اسم المتجر",
    formLabel3: "البريد الإلكتروني لتسجيل الدخول",
    formLabel4: "رمز الدعوة",
    formLabel5: "كلمة مرور تسجيل الدخول",
    formLabel6: "كلمة مرور الدفع",
    formLabel7: "رسالة الوصف",
    formLabel8: "حفظ صورة الخلفية",
    formLabel9: "صورة خلفية السوق",
    formLabel10: "معلومات الشهادة",
    formHint1: "واجهة المستند",
    formHint2: "الجانب العكسي للشهادة",
    fileFail: "يرجى تحميل الصور والتنسيق",
    optionsBtn: "إرسال",
  },
  'appShopInfoEdit': {
    navTitle: "تخزين المعلومات",
    formLabel1: "تخزين الصورة",
    formLabel2: "اسم المتجر",
    formLabel3: "البريد الإلكتروني لتسجيل الدخول",
    formLabel4: "رمز الدعوة",
    formLabel7: "رسالة الوصف",
    formLabel8: "حفظ صورة الخلفية",
    formLabel9: "صورة خلفية السوق",
    fileFail: "يرجى تحميل الصور والتنسيق",
    optionsBtn: "إرسال",
  },
  'appChangeLoginPass': {
    navTitle: "تغيير كلمة مرور تسجيل الدخول",
    formLabel1: "كلمة المرور القديمة",
    formLabel2: "كلمة المرور الجديدة",
    formLabel3: "رمز التحقق",
    formHint1: "الرجاء إدخال كلمة المرور القديمة",
    formHint2: "الرجاء إدخال كلمة مرور جديدة",
    formHint3: "الرجاء إدخال رمز التحقق",
    formOption: "إرسال",
    sendOption: "العد التنازلي:",
    optionsBtn: "تأكيد",
  },
  'appChangePayPass': {
    navTitle: "تغيير كلمة مرور الدفع",
    formLabel1: "كلمة مرور الدفع",
    formLabel2: "رمز التحقق",
    formHint1: "الرجاء إدخال كلمة مرور الدفع",
    formHint2: "الرجاء إدخال رمز التحقق",
    formOption: "إرسال",
    sendOption: "العد التنازلي:",
    optionsBtn: "تأكيد",
  },
  "appFundingRecordsIndex": {
    navTitle: "سجل الصندوق",
    searchRecordsTitle: "نوع الصندوق",
    searchDateTitle: "اختيار الوقت",
    searchFundingLabel1: "جميع الأموال",
    searchFundingLabel2: "نوع الإيداع",
    searchFundingLabel3: "سجل السحب",
    searchOptions1: "إعادة التعيين",
    searchOptions2: "تأكيد",
    searchDateAffirm: "تأكيد",
    searchDateClose: "إلغاء",
    stareTimePlace: "وقت البدء",
    endTimePlace: "وقت النهاية",
    typeAll: 'الكل',
  },
  "appProjectIndex": {
    navTitle: "قائمة المنتجات",
    searchPlace: "الرجاء إدخال اسم المنتج الذي تريد البحث عنه",
    shelvesLabel: 'الرفوف العلوية والسفلية',
    brandPlace: "الرجاء تحديد علامة تجارية",
    classPlace: "الرجاء تحديد فئة",
    searchOptions1: "إعادة تعيين",
    searchOptions2: "تأكيد",
    shelvesTag1: "الكل",
    shelvesTag2: "على الرفوف",
    shelvesTag3: "خارج الرفوف",
    salesLabel: 'المبيعات:',
    dialogAffirm: "تأكيد",
    dialogClose: "إلغاء",
    listLabel: 'الرفوف العلوية والسفلية:',
  },
  "appProjectLibraryIndex": {
    navTitle: "مكتبة المنتجات",
    searchPlace: "الرجاء إدخال اسم المنتج الذي تريد البحث عنه",
    brandPlace: "الرجاء تحديد علامة تجارية",
    classPlace: "الرجاء تحديد فئة",
    searchOptions1: "إعادة تعيين",
    searchOptions2: "تأكيد",
    salesLabel: 'المبيعات:',
    dialogAffirm: "تأكيد",
    dialogClose: "إلغاء",

    productPackageLabel1: " حزمة المنتج ",
    productPackageLabel2: ' ( إضافة المنتج بسرعة ) ',
    selectAll: 'اختيار متعددة',
    selectHint: 'دعم ما يصل إلى 50 المنتجات فقط',
    submitOptions: 'قدم',
    dialogHint: 'هل أنت متأكد من أن الكثير من هذا المنتج على الرف ؟', 
  },
  'appProductPackageIndex': {
    navTitle: "حزمة المنتج ",
    listLabel1: 'أوصى نجوم: ',
    listLabel2: 'حالة التزامن:',
    listLabel3: 'كمية المنتج: ',
    optionsBtn: 'مزامنة',
    dialogHint: 'هل أنت متأكد من أنك تستخدم هذه الحزمة ؟',
    dialogOptions1: 'ألغى',
    dialogOptions2: 'أكّد',
    messageToast: 'ارتكبت بنجاح ، بيانات المنتج التزامن .',
  },
  'appProductPackageList': {
    navTitle: " تفاصيل المنتج حزمة ",
    salesLabel: 'بيع:'
  },
  "appProductFirstIndex": {
    navTitle: "معلومات المنتج",
    nextStep: "الخطوة التالية",
    label1: "اسم المنتج:",
    label2: "العلامة التجارية للمنتج:",
    label3: "فئة المنتج:",
    label4: "الحد الأدنى لسعر الشراء:",
    label5: "الحد الأدنى لسعر البيع:",
    label6: "الحد الأدنى لسعر السوق:",
    label7: "صورة الغلاف:",
    label8: "صورة المنتج:",
  },
  'appProductNextStepIndex': {
    navTitle: "مواصفات المنتج",
    previous: "الخطوة السابقة",
    navBtn: "إرسال",
    label1: "سعر الشراء:",
    placeHint: "الرجاء الدخول",
    label2: "سعر البيع:",
    label3: "سعر السوق:",
    specLabel: 'سمة المواصفات:',
    optionBtn: "تأكيد",
    validateMessage1: "لا يمكن أن يكون السعر الأصلي أقل من سعر الشراء",
    validateMessage2: "لا يمكن أن يكون السعر الأصلي أقل من سعر البيع الحالي",
    validateMessage3: "لا يمكن أن يكون سعر البيع أقل من سعر الشراء",
    validateMessage4: "لا يمكن أن يكون سعر البيع أكبر من السعر الأصلي",
    shelvesTitle: " هل تريد تحميل الرفوف أو تفريغها؟ ",
    shelvesOptions1: "لا",
    shelvesOptions2: "نعم",
  },
  "appOrderIndex": {
    tagsLabel1: "الكل",
    orderTotalNumber: "إجمالي رقم الطلب",
    listLabel1: "تاريخ الطلب:",
    listLabel2: 'إجمالي المبيعات:',
    listLabel3: "كمية المنتج:",
    listLabel4: "حالة الطلب:",
    listLabel5: "حالة الدفع:",
    listHint: "شراء",
    searchOptionBtn: "بحث",
    searchPlace: "الرجاء إدخال محتوى البحث",
    searchDateStartPlace: "وقت البدء",
    searchDateEndPlace: "وقت الانتهاء",
    searchDateAffirm: "تأكيد",
    searchDateClose: "إلغاء",
    searchTypePayTitle: "حالة الدفع",
    payTags1: "الكل",
    payTags2: "في انتظار الدفع",
    payTags3: "مدفوع",
    searchTypeOrderTitle: "حالة الطلب",
    searchOptions1: "إعادة التعيين",
    searchOptions2: "تأكيد",
    purDialogTitle: "شراء الكل",
    purDialogLabel1: 'مبلغ الشراء:',
    purDialogLabel2: 'مبلغ المبيعات:',
    purDialogLabel3: 'مبلغ الربح:',
    purOptions: "تأكيد",
    purRowTitle: "شراء",
    purRowLabel1: 'مبلغ الشراء:',
    purRowLabel2: 'مبلغ المبيعات:',
    purRowLabel3: 'مبلغ الربح:',
  },
  "appOrderDetailIndex": {
    navTitle: "تفاصيل الطلب",
    detailInfoLabel1: 'رقم الطلب:',
    detailInfoLabel2: 'وقت الطلب:',
    detailInfoLabel3: 'طريقة الدفع:',
    detailInfoLabel4: 'حالة الطلب:',
    detailInfoLabel5: 'حالة الدفع:',
    productLabel1: "معلومات المنتج",
    productAttributesLabel: 'سمات المنتج:',
    productAccountLabel1: 'المبيعات:',
    productAccountLabel2: 'مبلغ الشراء:',
    countInfoLabel1: 'إجمالي المبيعات:',
    countInfoLabel2: 'إجمالي مبلغ الشراء:',
    countInfoLabel3: 'إجمالي الربح:',
    countInfoLabel4: 'الكمية الإجمالية:',
    countInfoLabel5: 'نسبة الخصم:',
  },
  "appOrderDetailLogistics": {
    navTitle: "طلب الخدمات اللوجستية",
    logisticsLabel: "الطلب",
    listLabel1: "الاسم:",
    listLabel2: "الهاتف:",
    listLabel3: "الرمز البريدي:",
    listLabel4: "العنوان:",
  },
  "appOrderDetailRecord": {
    navTitle: "سجل الطلب",
    logisticsLabel: "الطلب",
    recordEnumLabel1: "يقوم العميل بإنشاء الطلب",
    recordEnumLabel2: "أمر الدفع الخاص بالعميل",
    recordEnumLabel3: "أمر شراء المتجر",
    recordEnumLabel4: "يبدأ شحن الطلب",
    recordEnumLabel5: "يؤكد المستخدم الاستلام",
    recordEnumLabel6: "وصول سداد النظام",
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: "ترقية الإصدار، يرجى التأكيد؟",
    loadingTitle: "جارٍ التنزيل...",
    systemUpgrade: "ترقية النظام",
    upgradeImmediately: "تأكيد",
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "جارٍ التحميل",
    finishedText: "لا شيء",
    loadingText: "جارٍ التحميل",
    nullText: "لا توجد بيانات بعد",
    loadingFinished: "اكتمل التحميل",
    listNullText: "لا أكثر",
    pullIngText: "اسحب للأسفل للتحديث",
    loosingText: "الإصدار للتحديث",
    pullingSuccess: "تم التحميل المنسدل بنجاح",
    webSocketSuccess: "لديك رسالة جديدة",
    imLinkSuccess: 'تم الاتصال بخدمة المراسلة الفورية بنجاح! ',
    imNonsupport: "هذا المتصفح لا يدعم websocket",
    imLinkLoading: 'يتم الآن ربط المراسلة الفورية، يرجى الانتظار...',
    imLinkErrorLoading: 'فشل اتصال المراسلة الفورية، يرجى تحديث الصفحة أو الاتصال بالمطور! ',
    dialogHint: 'هل لديك حساب ؟',
    dialogClose: 'التسجيل',
    dialogAffirm: 'تسجيل الدخول',
  },
};
export default obj;
