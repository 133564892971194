// 繁体中文
let obj = {};
obj = {
  // 语言
  languageVersion: [
    'English',  // 英语
    '日本語', // 日语
    'Português', // 葡萄牙语
    '繁體中文', // 繁体中文
    'Français', // 法语
    'Deutsch', // 德语
    'Türk', // 土耳其语
    'Español', // 西班牙语
    'عربي', // 阿拉伯语
    '한국인', // 韩语
    'Suomi', // 芬兰语
    'Русский', // 俄语
    'italiano', // 意大利语
    'แบบไทย', // 泰语
    'Nederlands', // 荷兰语
  ],
  "appWebSocketList": {
    navTitle: '訊息',
    IMErrorMessage: 'IM連線失敗，點選重試！ ',
  },
  "appWebSocketDetails": {
    sendOption: '發送',
    send: '發送',
    fileFail: '請上傳&格式圖片',
    IMportError: '目前未連接，請重試！ ',
    IMErrorMessage: 'IM連線失敗，請重試！ ',
    retryBtn: '重試',
  },
  "appLoginIndex": {
    navTitle: '登录',
    projectName: '管理店鋪',
    formLabel1: '郵箱',
    formLabel2: '密碼',
    placeholder: '請輸入',
    hint: '如果沒有帳號，',
    clickRegister: '點選註冊',
    forgetPass: '忘記密碼',
    optionBtn: '登陸',
    validateError: '請完成輸入！ '
  },
  "appForgetPassIndex": {
    navTitle: '忘記密碼',
    formLabel1: '登入信箱',
    formLabel2: '驗證碼',
    formLabel3: '新密碼',
    formLabel4: '確認密碼',
    formHint1: '請輸入登入信箱',
    formHint2: '請輸入新密碼',
    formHint3: '請輸入驗證碼',
    formHint4: '請二次輸入密碼',
    formOption: '發送',
    sendOption: '倒數計時：',
    optionsBtn: '確認'
  },
  'footer': {
    home: "店家",
    project: '產品',
    info: '訊息',
    order: '訂單',
    user: '我的',
  },
  'setDrawer': {
    label1: '多語言設定',
    label2: '商店註冊',
    label3: '商店登入',
    label4: '客戶服務',
    label5: '下載 Android 應用程式',
    label6: '下載ios應用程式',
    optionsBtn: '取消',
  },
  'appHomeIndex': {
    accountLabel1: '銷售',
    accountLabel2: '流量',
    cardLabel1: '資料概覽',
    cardLabel2: '數據報告',
    cardLabel3: '金額概覽',
    ech1Title: '總銷售額',
    ech2Title: '總訂單數',
    ech3Title: '店鋪流量',
    echBuoy1Title: '總銷售額',
    echBuoy2Title: '總訂單數',
    echBuoy3Title: '店鋪流量',
    dataCardTitle1: '銷售額',
    dataCardTitle2: '流量',
    dataCardTitle3: '訂單總數',
    dataCardTitle4: '未償還總額',
    dataCardDayTags: '今日',
    dataYesterTags: "昨天的資料",
    dataYesterTags1: "未結帳訂單數量",
    cardMenusLabel1: '納稅',
    cardMenusLabel2: '儲值',
    cardMenusLabel3: '提款',
    cardMenusLabel4: '分享邀請',
    cardMenusLabel5: '產品清單',
    cardMenusLabel6: '訂單清單',
    cardMenusLabel7: '業務報告',
    taxPopupTitle: '目前需補稅金：',
    popupBtn: '去補稅',
    cardStateLabel1: '餘額',
    cardStateLabel2: '提款',
    cardStateLabel3: '收入',
    cardStateLabel4: '團隊',
    cardSellHotTitle: '熱賣商品Top 10',
    salesLabel: '銷售：',
  },
  'appSetLanguage': {
    navTitle: '多語言設定',
    affirm: '確認',
  },
  "appServiceIndex": {
    navTitle: '顧客服務',
    customerService: '專屬客戶服務',
    helloTitle: '你好',
    welcomeTitle: '我是你的<，你可以透過以下管道聯絡我',
    iconInformation: '請選擇通訊軟體進行諮詢',
    whatsApp: 'whatsApp ',
    telegram: 'telegram ',
    line: 'line',
  },
  'appUserIndex': {
    blanceLabel: '餘額',
    blanceOption1: '提現',
    blanceOption2: '儲值',
    incomeLabel1: '累計收益',
    incomeLabel2: '累計提款',
    listLabel1: '店鋪資訊',
    listLabel2: '提現方式設定',
    listLabel3: '資金紀錄',
    listLabel4: '營業報表',
    listLabel5: '折扣活動',
    listLabel7: '貸款',
    listLabel8: '前往商城',
    listLabel9: '下載應用程式',
    listLabelLast: '退出',
  },
  'downloadAppIndex': {
    navTitle: '下載',
    downloadAndroidApp: "Android端下載",
    downloadIosApp: "IOS端下載",
  },
  "appDiscountActivity": {
    navTitle: '折扣活動',
    popupLabel1: '金額',
    popupPlace1: '請輸入金額',
    popupLabel2: '折扣',
    popupPlace2: '請輸入折扣',
    popupOption: '確認',
    listLabel1: '所需金額：',
    listLabel2: '折扣比例：',
    listLabel3: '折扣時間：',
    dialogTitle: "確認是否刪除！",
    dialogAffirm: '確認',
    dialogClose: "取消"
  },
  "appInviteIndex": {
    navTitle: '邀新活动',
    headerTitle: '邀好友赚奖励',
    copyOption: '复制',
    copySuccess: '复制成功',
    inviteTitle: '邀请总数',
    inviteLabel1: '邀请人数：',
    inviteLabel2: '佣金总数：',
    ruleTitle: '邀请规则',
    ruleHint1: ' 1. 邀請人可通過分享推廣鏈接方式邀請好友完成註冊，被邀請的好友將與您關聯；',
    ruleHint2: ' 2. 邀請用戶完成回款後你可以獲得其收益的分成；',
    ruleHint3: ' 3. 僅支持三級邀請獎勵，獎勵比例為：<%，>%，@%； ',
  },
  "appInviteUserRecordIndex": {
    navTitle: '邀請使用者記錄',
    recordTitle1: '用戶總數',
    recordTitle2: '一級',
    recordTitle3: '二級',
    recordTitle4: '三級',
    tabLabel1: '所有',
    tabLabel2: '一級',
    tabLabel3: '二級',
    tabLabel4: '三級',
    listLabel1: '等級：',
    listLabel2: '收益：'
  },
  "appInviteIncomeRecordIndex": {
    navTitle: '邀請收益紀錄',
    recordTitle1: '收益總數',
    recordTitle2: '一級',
    recordTitle3: '二級',
    recordTitle4: '三級',
    tabLabel1: '所有',
    tabLabel2: '一級',
    tabLabel3: '二級',
    tabLabel4: '三級',
    listLabel1: '收益：',
    listLabel2: '比例 ：',
  },
  "appWithdrawIndex": {
    navTitle: '提現',
    currencyType1: '法定貨幣',
    currencyType2: '虛擬貨幣',
    formLabel1: '貨幣類型：',
    formPlace1: '請選擇貨幣類型',
    formLabel2: '選擇貨幣：',
    formPlace2: '請選擇貨幣類型',
    formLabel3: '提現金額：',
    formPlace3: '請輸入提現金額',
    formLabel4: '提現密碼：',
    formPlace4: '請輸入提現密碼：',
    balanceLabel: '當前餘額：',
    amountLabel: '實際到貨：',
    optionsBtn: '提交',
    dialogClose: '取消',
    dialogAffirm: '確認',
    currencySelectError: '請選擇貨幣類型',
    currencyError: '請選擇貨幣',
    affirmDialogDialogTitle1: '您尚未設定付款密碼。你想去設定一下嗎？ ',
    affirmDialogDialogTitle2: '您尚未設定提現頻道，是否要設定？ ',
    affirmDialogBtn2: '取消',
    affirmDialogBtn1: '確認',
  },
  'appSetWithdrawalIndex': {
    navTitle: '提現方式設定',
    currencyType1: '法定貨幣',
    currencyType2: '虛擬貨幣',
    formLabel1: '貨幣類型：',
    formPlace1: '請選擇貨幣類型',
    formLabel2: '選擇貨幣：',
    formPlace2: '請選擇貨幣類型',
    addressLabel: '地址：',
    addressPlace: '請輸入虛擬幣地址',
    hintLabel: '重要提示',
    hintValue: '只允許轉帳¥資產',
    optionsBtn: '提交',
    dialogClose: '取消',
    dialogAffirm: '確認',
    currencySelectError: '請選擇貨幣類型',
    currencyError: '請選擇貨幣',
    currencyFail: '尚未開放',
  },
  //  提现设置-法定货币-BRL
  'brlLanguage': {
    formLabel1: '真實姓名',
    formPlace1: '請輸入真實姓名',
    formLabel2: '郵箱',
    formPlace2: '請輸入郵箱',
    formLabel3: '手機號',
    formPlace3: '請輸入手機號',
    formLabel4: 'PIX類型',
    formPlace4: '請選擇PIX類型',
    formLabel5: 'PIX賬號',
    formPlace5: '請輸入PIX賬號',
    formLabel6: '銀行名稱',
    formPlace6: '請輸入銀行名稱',
    formLabel7: '銀行賬戶',
    formPlace7: '請輸入銀行賬戶',
  },
  //  提现设置-法定货币-EUR
  'eurLanguage': {
    formLabel1: '國家/地區',
    formPlace1: '請輸入國家/地區',
    formLabel2: '名稱',
    formPlace2: '請輸入名稱',
    formLabel3: '姓氏',
    formPlace3: '請輸入姓氏',
    formLabel4: 'swift',
    formPlace4: '請輸入swift',
    formLabel5: '銀行名稱',
    formPlace5: '請輸入銀行名稱',
    formLabel6: 'iban',
    formPlace6: '請輸入iban',
    formLabel7: '手機號',
    formPlace7: '請輸入手機號',
  },
  //  提现设置-法定货币-USD
  'usdLanguage': {
    formLabel1: '國家/地區',
    formPlace1: '請輸入國家/地區',
    formLabel2: '名稱',
    formPlace2: '請輸入名稱',
    formLabel3: '姓氏',
    formPlace3: '請輸入姓氏',
    formLabel4: 'swift',
    formPlace4: '請輸入swift',
    formLabel5: 'ach',
    formPlace5: '請輸入ach',
    formLabel6: '銀行名稱',
    formPlace6: '請輸入銀行名稱',
    formLabel7: '銀行賬戶',
    formPlace7: '請輸入銀行賬戶',
    formLabel8: '省或州',
    formPlace8: '請輸入省或州',
    formLabel9: '手機號',
    formPlace9: '請輸入手機號',
  },
  //  提现设置-法定货币-GBP
  'gbpLanguage': {
    formLabel1: '國家/地區',
    formPlace1: '請輸入國家/地區',
    formLabel2: '名稱',
    formPlace2: '請輸入名稱',
    formLabel3: '姓氏',
    formPlace3: '請輸入姓氏',
    formLabel4: '銀行代碼',
    formPlace4: '請輸入銀行代碼',
    formLabel5: '銀行名稱',
    formPlace5: '請輸入銀行名稱',
    formLabel6: '銀行賬戶',
    formPlace6: '請輸入銀行賬戶',
    formLabel7: '手機號',
    formPlace7: '請輸入手機號',
  },
  //  提现设置-法定货币-JPY
  'jpyLanguage': {
    formLabel1: '國家/地區',
    formPlace1: '請輸入國家/地區',
    formLabel2: '名稱',
    formPlace2: '請輸入名稱',
    formLabel3: '姓氏',
    formPlace3: '請輸入姓氏',
    formLabel4: 'zengin',
    formPlace4: '請輸入zengin',
    formLabel5: '銀行名稱',
    formPlace5: '請輸入銀行名稱',
    formLabel6: '銀行賬戶',
    formPlace6: '請輸入銀行賬戶',
    formLabel7: '省或州',
    formPlace7: '請輸入省或州',
    formLabel8: '手機號',
    formPlace8: '請輸入手機號',
  },
  //  提现设置-法定货币-AUD
  'audLanguage': {
    formLabel1: '國家/地區',
    formPlace1: '請輸入國家/地區',
    formLabel2: '名稱',
    formPlace2: '請輸入名稱',
    formLabel3: '姓氏',
    formPlace3: '請輸入姓氏',
    formLabel4: 'BSB',
    formPlace4: '請輸入BSB',
    formLabel5: '銀行名稱',
    formPlace5: '請輸入銀行名稱',
    formLabel6: '銀行賬戶',
    formPlace6: '請輸入銀行賬戶',
    formLabel7: '省或州',
    formPlace7: '請輸入省或州',
    formLabel8: '手機號',
    formPlace8: '請輸入手機號',
  },
  //  提现设置-法定货币-krw
  'krwLanguage': {
    formLabel1: '名稱',
    formPlace1: '請輸入名稱',
    formLabel2: '姓氏',
    formPlace2: '請輸入姓氏',
    formLabel3: '銀行名稱',
    formPlace3: '請輸入銀行名稱',
    formLabel4: '銀行賬戶',
    formPlace4: '請輸入銀行賬戶',
    formLabel5: '手機號',
    formPlace5: '請輸入手機號',
  }
  ,
  //  提现设置-法定货币-mxn
  'mxnLanguage': {
    formLabel1: '銀行名稱',
    formPlace1: '請輸入銀行名稱',
    formLabel2: '賬戶',
    formPlace2: '請輸入賬戶',
    formLabel3: 'clabe賬戶',
    formPlace3: '請輸入clabe賬戶',
    formLabel4: '手機號',
    formPlace4: '請輸入手機號',
  },
  'appStatisticsReportIndex': {
    navTitle: '經營報表',
    cardLabel1: '總銷售額',
    cardLabel2: '總採購額',
    cardLabel3: '總利潤額',
    cardLabel4: '訂單總數',
    listLabel1: '時間：',
    listLabel2: '訂單：',
    listLabel3: '銷售額:',
    listLabel4: '採購額:',
    profitLabel: '利潤',
    searchDateTitle: '單據時間',
    searchIntervalTitle: '區間時間',
    searchStartTimePlace: '開始時間',
    searchEndTimePlace: '結束時間',
    searchOptions1: '重置',
    searchOptions2: '確認',
    searchDateAffirm: '確認',
    searchDateClose: '取消',
    searchIntervalLabel1: '全部',
    searchIntervalLabel2: '今日',
    searchIntervalLabel3: '昨日',
    searchIntervalLabel4: '本週',
    searchIntervalLabel5: '本月',
    searchIntervalLabel6: '本年',
    searchIntervalOptions: '取消',

  },
  "appAmountRechargeIndex": {
    navTitle: '儲值',
    currencyType1: '法定貨幣',
    currencyType2: '虛擬貨幣',
    formLabel1: '貨幣類型：',
    formPlace1: '請選擇貨幣類型',
    formLabel2: '選擇幣種：',
    formPlace2: '請選擇幣種類型',
    formLabel3: '儲值金額：',
    formPlace3: '請輸入儲值金額',
    formLabel4: '充值方式',
    formPlace4: '請選擇儲值方式：',
    balanceLabel: '當前餘額：',
    amountLabel: '實際到帳：',
    optionsBtn: '提交',
    dialogClose: '取消',
    dialogAffirm: '確認',
    currencySelectError: '請選擇貨幣類型',
    currencyError: '請選擇幣種',
    successCopy: '複製成功',
    hintLabel: "重要提示：",
    hintValue: '僅允許轉入¥資產',
    legalHint: '請聯系客服！'
  },
  'appShopInfoIndex': {
    navTitle: '商店資訊',
    navEditText: '編輯',
    formLabel1: '店鋪影像',
    formLabel2: '店鋪名稱',
    formLabel3: '登录電子郵件',
    formLabel4: '邀請碼',
    formLabel5: '登入密碼',
    formLabel6: '支付密碼',
    formLabel7: '描述訊息',
    formLabel8: '儲存背景圖片',
    formLabel9: '市場背景圖片',
    formLabel10: '證書資訊',
    formHint1: '文檔正面',
    formHint2: '憑證的反面',
    fileFail: '請上傳圖片並格式化',
    optionsBtn: '提交',
  },
  'appShopInfoEdit': {
    navTitle: '商店資訊編輯',
    formLabel1: '店鋪影像',
    formLabel2: '店鋪名稱',
    formLabel3: '登录電子郵件',
    formLabel4: '邀請碼',
    formLabel7: '描述訊息',
    formLabel8: '儲存背景圖片',
    formLabel9: '市場背景圖片',
    fileFail: '請上傳圖片並格式化',
    optionsBtn: '提交',
  },
  'appChangeLoginPass': {
    navTitle: '修改登录密碼',
    formLabel1: '舊密碼',
    formLabel2: '新密碼',
    formLabel3: '驗證碼',
    formHint1: '請輸入舊密碼',
    formHint2: '請輸入新密碼',
    formHint3: '請輸入驗證碼',
    formOption: '發送',
    sendOption: '倒數計時：',
    optionsBtn: '確認',
  },
  'appChangePayPass': {
    navTitle: '修改支付密碼',
    formLabel1: '支付密碼',
    formLabel2: '驗證碼',
    formHint1: '請輸入付款密碼',
    formHint2: '請輸入驗證碼',
    formOption: '發送',
    sendOption: '倒數計時：',
    optionsBtn: '確認',
  },
  "appFundingRecordsIndex": {
    navTitle: '資金紀錄',
    searchRecordsTitle: '資金類型',
    searchDateTitle: '時間選擇',
    searchFundingLabel1: '所有資金',
    searchFundingLabel2: '存款類型',
    searchFundingLabel3: '提現記錄',
    searchOptions1: '重置',
    searchOptions2: '確認',
    searchDateAffirm: '確認',
    searchDateClose: '取消',
    stareTimePlace: '開始時間',
    endTimePlace: '結束時間',
    typeAll: '全部',
  },
  "appProjectIndex": {
    navTitle: '產品清單',
    searchPlace: '請輸入要搜尋的產品名稱',
    shelvesLabel: '上下架',
    brandPlace: '請選擇品牌',
    classPlace: '請選擇類別',
    searchOptions1: "重置",
    searchOptions2: '確認',
    shelvesTag1: "全部",
    shelvesTag2: "上架",
    shelvesTag3: "下架",
    salesLabel: '銷售：',
    dialogAffirm: '确认',
    dialogClose: '取消',
    listLabel: '上下架：',
  },
  "appProjectLibraryIndex": {
    navTitle: "產品庫",
    searchPlace: '請輸入要搜尋的產品名稱',
    brandPlace: '請選擇品牌',
    classPlace: '請選擇類別',
    searchOptions1: "重置",
    searchOptions2: '確認',
    salesLabel: '銷售：',
    dialogAffirm: '確認',
    dialogClose: '取消',
    productPackageLabel1: '產品包',
    productPackageLabel2: '（快速添加產品）',

    selectAll: '多選',
    selectHint: '僅支持最多50件產品',
    submitOptions: '提交',
    dialogHint: '確定批量上架該產品嗎？',

  },
  'appProductPackageIndex': {
    navTitle: '產品包',
    listLabel1: '推薦星級：',
    listLabel2: '同步狀態：',
    listLabel3: '產品數量：',
    optionsBtn: '同步',
    dialogHint: '確認使用該產品包嗎？',
    dialogOptions1: '取消',
    dialogOptions2: '確認',
    messageToast: '提交成功，產品資料同步中…',
  },
  'appProductPackageList': {
    navTitle: '产品包详情',
    salesLabel: '銷售：',
  },
  "appProductFirstIndex": {
    navTitle: '產品資訊',
    nextStep: '下一步',
    label1: '產品名稱：',
    label2: '產品品牌：',
    label3: '產品類別：',
    label4: '最低購買價：',
    label5: '最低銷售價：',
    label6: '最低市場價：',
    label7: '封面圖：',
    label8: '產品圖：',

  },
  'appProductNextStepIndex': {
    navTitle: '產品規格',
    previous: '上一步',
    navBtn: '提交',
    label1: '購買價格：',
    placeHint: '請輸入',
    label2: '銷售價格：',
    label3: '市場價格：',
    specLabel: '規格屬性：',
    optionBtn: "確認",
    validateMessage1: '原價不能低於購買價',
    validateMessage2: '原價不能低於目前銷售價',
    validateMessage3: '銷售價格不能低於購買價格',
    validateMessage4: '銷售價格不能大於原價',
    shelvesTitle: '是否上下架？ ',
    shelvesOptions1: '否',
    shelvesOptions2: '是',
  },
  "appOrderIndex": {
    tagsLabel1: '全部',
    orderTotalNumber: '總訂單數',
    listLabel1: '訂單日期：',
    listLabel2: '總銷售額：',
    listLabel3: '產品數量：',
    listLabel4: '訂單狀態：',
    listLabel5: '付款狀態：',
    listHint: '購買',
    searchOptionBtn: '搜尋',
    searchPlace: '請輸入搜尋內容',
    searchDateStartPlace: '開始時間',
    searchDateEndPlace: '結束時間',
    searchDateAffirm: '確認',
    searchDateClose: '取消',
    searchTypePayTitle: '付款狀態',
    payTags1: '全部',
    payTags2: '待付款',
    payTags3: '已付費',
    searchTypeOrderTitle: '訂單狀態',
    searchOptions1: '重置',
    searchOptions2: '確認',
    purDialogTitle: '全部購買',
    purDialogLabel1: '購買金額：',
    purDialogLabel2: '銷售金額：',
    purDialogLabel3: '利潤金額：',
    purOptions: "確認",
    purRowTitle: '購買',
    purRowLabel1: '購買金額：',
    purRowLabel2: '銷售金額：',
    purRowLabel3: '利潤金額：',
  },
  "appOrderDetailIndex": {
    navTitle: '訂單詳情',
    detailInfoLabel1: '訂單編號：',
    detailInfoLabel2: '下單時間：',
    detailInfoLabel3: '付款方式：',
    detailInfoLabel4: '訂單狀態：',
    detailInfoLabel5: '付款狀態：',
    productLabel1: '產品資訊',
    productAttributesLabel: '產品屬性：',
    productAccountLabel1: '銷售額：',
    productAccountLabel2: '購買金額：',
    countInfoLabel1: '總銷售額：',
    countInfoLabel2: '總購買量：',
    countInfoLabel3: '總利潤：',
    countInfoLabel4: '總數量：',
    countInfoLabel5: '折扣比例：',
  },
  "appOrderDetailLogistics": {
    navTitle: '訂單物流',
    logisticsLabel: '訂單',
    listLabel1: "姓名：",
    listLabel2: "電話：",
    listLabel3: "郵編：",
    listLabel4: "地址：",
  },
  "appOrderDetailRecord": {
    navTitle: '訂單記錄',
    logisticsLabel: '訂單',
    recordEnumLabel1: '客戶創建訂單',
    recordEnumLabel2: '客戶支付訂單',
    recordEnumLabel3: '店家採購訂單',
    recordEnumLabel4: '訂單開始發貨',
    recordEnumLabel5: '用戶確認收貨',
    recordEnumLabel6: '系統回款到賬',
  },
  // app升级配置文件语言
  configLanguage: {
    upgradePrompt: '版本升級，請確認？',
    loadingTitle: '正在下載...',
    systemUpgrade: '系統升級',
    upgradeImmediately: '確認',
  },
  // 公共提示
  messageLanguage: {
    loadingTitle: "正在載入", // 正在載入
    finishedText: "無", //無
    loadingText: "正在載入", // 正在載入
    nullText: '還沒有資料',
    loadingFinished: "載入完成",
    listNullText: '沒有了',
    pullIngText: '下拉刷新',
    loosingText: '釋放刷新',
    pullingSuccess: '下拉載入成功',

    webSocketSuccess: '您有一條新消息',
    imLinkSuccess: 'IM服務連接成功！',
    imNonsupport: '該瀏覽器不支持websocket',
    imLinkLoading: 'IM正在連結中，請稍後…',
    imLinkErrorLoading: 'IM連接失敗，請刷新頁面或聯系開發人員！',
    dialogHint: '您是否已有帳號？',
    dialogClose: '注册',
    dialogAffirm: '登入',
  },
};
export default obj;
